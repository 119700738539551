import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React, { useState, useCallback, useContext } from 'react';
import CaseWebformModal from './CaseWebformModal';
import { AppContext } from "../util/appContext";
import { hooks } from "botframework-webchat-component";

const classNames = require('classnames');
const { useSendEvent } = hooks;

const CaseWebformCard = ({ content }) => {
    const [showArticleModal, setArticleModal] = useState(false);
    const context = useContext(AppContext);
    const sendMessage = useSendEvent();

    const articleButton = classNames({
        'btn-secondary': true,
        'article-button': true,
        'case-webform': true
    });

    window.onmessage = (e) => {
        switch (e.data.key) {
            case 'display_article_popup':
                document.getElementById('root').classList.remove('root-min');
                break;
            case 'hide_article_popup':
                document.getElementById('root').classList.add('root-min');
                break;
            default:
                break;
        }
    };

    const handleArticleModalSubmit = useCallback((event, comments, communicationMode, phoneNumber) => {
        context.setMenuOpen(false);
        if (showArticleModal) {
            window.parent.postMessage({ key: 'display_article_popup', origin: window.location.origin }, window.config.pageOrigin);
        } else {
            window.parent.postMessage({ key: 'hide_article_popup', origin: window.location.origin }, window.config.pageOrigin);
        }
        setArticleModal(!showArticleModal);
        sendMessage('webformPopupSubmit', { webformComment: comments, preferredCommunicationMode: communicationMode + " " + phoneNumber });
    }, [showArticleModal, setArticleModal]);

    const handleArticleModalClose = useCallback(event => {
        context.setMenuOpen(false);
        if (showArticleModal) {
            window.parent.postMessage({ key: 'display_article_popup', origin: window.location.origin }, window.config.pageOrigin);
        } else {
            window.parent.postMessage({ key: 'hide_article_popup', origin: window.location.origin }, window.config.pageOrigin);
        }

        setArticleModal(!showArticleModal);

    }, [showArticleModal, setArticleModal]);

    return (
        <div className="heroCardContainer">
            {!window.config.isNewUI && <div className="Icon"></div>}
            <div className="article-button-container">
                <button onClick={handleArticleModalClose} className={articleButton}>{content.Text}</button>
            </div>
            {showArticleModal ? <CaseWebformModal article={content} handleArticleModalClose={handleArticleModalClose} handleArticleModalSubmit={handleArticleModalSubmit} /> : null}
        </div>
    )
}

export default CaseWebformCard;

