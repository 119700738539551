import 'react-app-polyfill/ie11';
import 'core-js/stable';
import { useEffect} from 'react';

function useKeyPress(handler) {
    // State for keeping track of whether key is pressed
    // const [keyPressed, setKeyPressed] = useState(false);
  
   
  
  
    // Add event listeners
    useEffect(() => {

       // If pressed key is our target key then set to true
    function downHandler({ key }) {
      if (key === 'Escape') {
          handler([]);
        // setKeyPressed(true);
      }
    }
      window.addEventListener('keydown', downHandler);
    //   window.addEventListener('keyup', upHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener('keydown', downHandler);
        // window.removeEventListener('keyup', upHandler);
      };
    }); // Empty array ensures that effect is only run on mount and unmount
  
    return '';
  }

  export default useKeyPress;