import "react-app-polyfill/ie11";
import "core-js/stable";
import { hooks } from "botframework-webchat-component";
import setLastMessage from "../util/setLastMessage";
import React, { useCallback, useContext } from 'react';
import { AppContext } from "../util/appContext";
import WEB from '../images/svgLatest/Web.svg';
import { getCookie} from '../util/cookieActions';

var classNames = require("classnames");

const { useSendMessage, useSendEvent } = hooks;

const TridiumHeroCard = ({ content }) => {
  const sendMessage = useSendMessage();
  const sendEvent = useSendEvent();
  const context = useContext(AppContext);

  const handleClick = useCallback(
    value => event => {
      event.preventDefault();
      if(getCookie(window.config.businessUnit + '-EnableSendBox')) {
        sendEvent('enableSendBoxEventReceived');
      }
      context.setDisableSendBox(true);
      setLastMessage(value);
      sendMessage(value);
      context.setMenuOpen(false);
      content.buttons.map((item, index) => {
        if (item.button.type !== "Articlebutton" && item.button.type !== "openResultUrl") {
          content.buttons.splice(index);
        }
      });
    },
    [sendMessage]
  );

  var articleButton = classNames({
    'btn-secondary': true,
    'article-button': true
  });

  const handleArticleModal = useCallback((articleId) => event => {
    context.setMenuOpen(false);
    sendEvent('xSBGFetchData', { id: articleId });
    context.setArticlePopUp(true);
  }, []);

  var heroCardButton = classNames({
    heroCardButton: true,
    customscrollbar: true
  });


  return (
    <div className="heroCardContainer">
      <div className="heroCard">
        <div className={heroCardButton}>
          {content.buttons.length > 0 &&
            content.buttons.map((item, index) => {
              if (item.button.type === 'Articlebutton') {
                return (
                  <button onClick={handleArticleModal(item.KnowledgeArticleId)}>{item.button.title}</button>
                )
              }
              if (item.button.type === "imBack") {
                return (
                  <button onClick={handleClick(item.button.value)}>
                    {item.button.title}
                  </button>
                );
              }
              if (item.button.type === "openResultUrl") {
                return (
                  <button onClick={(e) => {
                    e.preventDefault();
                    window.open(item.button.value, "_blank");
                  }}>
                    {item.button.title}
                  </button>
                )
              }
              return "";
            })}
        </div>
        {content.buttons.length > 0 && <div className="extraButtons" >
          {content.buttons.map(item => {
            if (item.button.type === "openUrl") {
              return (
                <a href={item.button.value} target="_blank" class="viewMoreLink" style={{ backgroundColor: '#007BC2' }}>
                  <img src={WEB} alt="web" />
                  <p>{item.button.title}</p>
                </a>
              );
            }
            else if (item.button.type === "extra") {
              return (
                <button onClick={handleClick(item.button.value)}>
                  {item.button.title}
                </button>
              )
            }
          })
          }
        </div>
        }
      </div>
    </div>
  );
};

export default TridiumHeroCard;