import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React from 'react';
var classNames = require('classnames');


const ErrorHandler = ({message, isMaximised}) => {


    var errorHandlerClass = classNames({
          erroHandler: true,
          displayNone:!isMaximised
    })

 

    return(<div className={errorHandlerClass}>
        <div>
         {message.text}
         {message.subText && (
             <p style={{color:"rgb(105,105,105)",fontSize:"14px"}}>{message.subText}</p>
         )}
         {message.link &&(<p>
         <a target={message.link.target} style={{color:'blue'}} href={message.link.src} >{message.link.text}</a>
         </p>)}
         </div>
    </div>);
}

export default ErrorHandler;