import React from 'react';
import alertImage from "../images/alert.png"

export default ({content}) =>{
    return(
        <div className={"disclaimer-container"}>
            <div className="disclaimer-col-1">
            <img alt="Alert" src={alertImage}></img>
            </div>
            <div className="disclaimer-col-2">
             <div className="disclaimer-header">
              <p>{content.Title}</p>
             </div>
             <div className="disclaimer-sub-header">
             <p>{content.Subtitle}</p>
             </div>
            </div>
        </div>
    )
}
