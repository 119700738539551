import "react-app-polyfill/ie11";
import "core-js/stable";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { hooks } from "botframework-webchat-component";
import searchKBArticles from '../util/searchKBArticlesGDM.json';
import Select from "react-select";

var classNames = require("classnames");

const { useSendPostBack } = hooks;

const SearchKBFiltersGDM = ({ content, store }) => {
    const [division, setDivision] = useState(null);
    const [lob, setLob] = useState(null);
    const [productType, setProductType] = useState(null);
    const [noteBoxValue, setNoteBoxValue] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const sendPostBack = useSendPostBack();
    const divisionOptionList = useRef([]);
    const lobOptionList =  useRef([]);
    const productTypeOptionList = useRef([]);
    let divisionValue = "";

    if(searchKBArticles) {
        divisionOptionList.current = [];
        Object.keys(searchKBArticles).forEach((item) => {
            divisionOptionList.current.push({ value: item, label: item });
        });
    }
    useEffect(() => { 
    },[]);

    // Handle change division dropdown option
    const handleChangeDivision = useCallback(
        (e) => {
            setDivision(e);
            setLob("");
            setProductType("");
            divisionValue = e.value;
            lobOptionList.current = [];
            productTypeOptionList.current = [];
            if(searchKBArticles[e.value]) {
                Object.keys(searchKBArticles[e.value]).forEach((item) => {
                    lobOptionList.current.push({ value: item, label: item });
                });
            }
        },
        [setDivision, setLob, setProductType]
      );

      // Handle change LOB dropdown option
      const handleChangeLOB = useCallback(
        (e) => {
            setLob(e);
            setProductType("");
            productTypeOptionList.current = [];
            if(searchKBArticles[divisionValue] && searchKBArticles[divisionValue][e.value]) {
                searchKBArticles[divisionValue][e.value].map((item) => {
                    return productTypeOptionList.current.push({ value: item, label: item });
                    });
            }
        },
        [setLob, setProductType]
      );

      // Handle change product type dropdown option
      const handleChangeProductType = useCallback(
        (e) => {
            setProductType(e);
        },
        [setProductType]
      );
      
      // Handle change Notes text
      const handleChangeNotes = useCallback(
        ({ target: { value } }) => {
            setNoteBoxValue(value);
        },
        [noteBoxValue, setNoteBoxValue]
      );

      // Handle submit button click
      const handleSubmitClick = useCallback(
        event => {
            event.preventDefault();
            const submitObject = {
                'division':division.value,
                'lob':lob.value,
                'productType':productType.value,
                'notes':noteBoxValue
            };
            setIsSubmitted(true);
            sendPostBack(submitObject);    
        },
        [division,lob,productType,noteBoxValue,setIsSubmitted,sendPostBack]); 

    return (
        <div className="kBForm">
                <div className="webformInputLabel">
                    <span className="webformFieldTitle kBFields">Select Division:</span>
                </div>
                <div className="input-wrap">
                    <Select
                        className="input-wrap-text-plugin kBFieldSelect"
                        name="division"
                        id="division"
                        options={divisionOptionList.current}
                        placeholder="Select division"
                        value={division}
                        onChange={(e) => handleChangeDivision(e)}
                        isSearchable={true}
                    />
                </div>
              
                <div className="webformInputLabel">
                    <span className="webformFieldTitle kBFields">Line Of Business:</span>
                </div>
                <div className="input-wrap">
                    <Select
                        className="input-wrap-text-plugin kBFieldSelect"
                        name="lob"
                        id="lob"
                        options={lobOptionList.current}
                        placeholder="Please select division first"
                        value={lob}
                        onChange={(e) => handleChangeLOB(e)}
                        isSearchable={true}
                    />
                </div>
             
                <div className="webformInputLabel">
                    <span className="webformFieldTitle kBFields">Product Type:</span>
                </div>
                <div className="input-wrap">
                    <Select
                        className="input-wrap-text-plugin kBFieldSelect"
                        name="product"
                        id="product"
                        options={productTypeOptionList.current}
                        placeholder="Please select lob first"
                        value={productType}
                        onChange={(e) => handleChangeProductType(e)}
                        isSearchable={true}
                    />
                </div>
               
                <div className="webformInputLabel">
                    <span className="webformFieldTitle kBFields">Note:Use Keywords and short sentences</span>
                </div>
                <div className="input-wrap">
                    <input type="text" className="input-wrap-text kBFieldSelect" maxLength={50} value={noteBoxValue} onChange={handleChangeNotes}  />  
                </div>
                
                <div className="kBFormSubmitDiv">
                    <button onClick={handleSubmitClick}
                        className="webformButton primary kBFormSubmit"
                        disabled={!division || !lob || !productType || isSubmitted}>
                        Submit</button>
                </div>
                <br/>
        </div>
    );
};
export default SearchKBFiltersGDM;