import "react-app-polyfill/ie11";
import "core-js/stable";
import React, { useState, useCallback, useContext, useEffect } from "react";
import { hooks } from "botframework-webchat-component";
import { AppContext } from "../util/appContext";
import { getCookie } from "../util/cookieActions";


const { useSendPostBack } = hooks;

const SearchKBFiltersHPS = ({ content, store }) => {
    const searchKBArticles = content && content.isLACFilters ? require('../util/searchKBArticlesLACHPS.json'):require('../util/searchKBArticlesHPS.json');
    const context = useContext(AppContext);
    // const [pole, setPole] = useState("");
    const [country, setCountry] = useState(content && content.country ? content.country : "DEFAULT");
    const [lob, setLob] = useState("DEFAULT");
    const [productType, setProductType] = useState("DEFAULT");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const sendPostBack = useSendPostBack();
    const [globalDisabled,setGlobalDisabled] = useState(false)
      
    useEffect(() => {
        let checkHistory = getCookie(window.config.businessUnit + '-CheckOrderLob')
        if (checkHistory) {
            checkHistory = JSON.parse(checkHistory);
            let isExisting = Array.isArray(checkHistory) && checkHistory?.map(i => i?.id)?.includes(content?.confirmLineItemsButtonId)
            if (isExisting) {
                let found = checkHistory?.find(i => i?.id === content?.confirmLineItemsButtonId)
                setGlobalDisabled(true);
                if(found && found?.lob) setLob(found?.lob);
                if(found && found?.region) setCountry(found?.region);
                if(found && found?.productType) setProductType(found?.productType);
            }
        }
    },[]);

    // Handle change pole dropdown option
    // const handleChangePole = useCallback(
    //     (e) => {
    //         setPole(e.target.value);
    //         setCountry("");
    //         setLob("");
    //         setProductType("");
    //     },
    //     [setPole, setCountry, setLob, setProductType]
    //   );

      // Handle change Country dropdown option
      const handleChangeCountry = useCallback(
        (e) => {
            setCountry(e.target.value);
            setLob("DEFAULT");
            setProductType("DEFAULT");
        },
        [setCountry, setLob, setProductType]
      );

       // Handle change LOB dropdown option
      const handleChangeLOB = useCallback(
        (e) => {
            setLob(e.target.value);
            setProductType("DEFAULT");
        },
        [setLob, setProductType]
      );

      // Handle change product type dropdown option
      const handleChangeProductType = useCallback(
        (e) => {
            setProductType(e.target.value);
        },
        [setProductType]
      );
      

      // Handle submit button click
      const handleSubmitClick = useCallback(
        event => {
            event.preventDefault();
            const submitObject = {
                'country' : country,
                'lob': lob,
                'productType': productType
              };
            setIsSubmitted(true);
            sendPostBack(submitObject);    
            if(!content.isLACFilters)context.setCaseWebFormArticleModal(true);
        },
        [country, lob, productType, setIsSubmitted, sendPostBack]); 
  
    return (
        <div className="kBForm kbFormCustomMargin">
                {/* <div className="webformInputLabel">
                    <span className="webformFieldTitle kBFields">Pole:</span>
                </div>
                <div className="input-wrap">
                    <select className="input-wrap-text kBFieldSelect" name="pole" 
                            id="pole" defaultValue={'DEFAULT'} 
                            onChange={(e) => handleChangePole(e)}>
                            
                            <option className="input-wrap-text" value="DEFAULT" >Select pole</option>
                            {searchKBArticles && Object.keys(searchKBArticles).map((item) => {
                            return ( <option className="input-wrap-text" value={item} >{item}</option>)
                            })} 
                    </select>
                </div> */}
              
                <div className="webformInputLabel">
                    <span className="webformFieldTitle kBFields">Geography:</span>
                </div>
                <div className="input-wrap">
                    <select className="input-wrap-text kBFieldSelect" name="country" value={country}
                               id={content.confirmLineItemsButtonId?content.confirmLineItemsButtonId+"_country":"country"}
                               defaultValue={'DEFAULT'}
                    onChange={(e) => handleChangeCountry(e)}
                    disabled={ globalDisabled}       
                
                >

                            <option className="input-wrap-text" value="DEFAULT" >Select geography</option>
                            {searchKBArticles && Object.keys(searchKBArticles).map((item) => {
                            return ( <option className="input-wrap-text" value={item} >{item}</option>)
                            })}
                    </select>
                </div>

                <div className="webformInputLabel">
                    <span className="webformFieldTitle kBFields">Line Of Business:</span>
                </div>
                <div className="input-wrap">
                    <select className="input-wrap-text kBFieldSelect" name="lob" value={lob}
                                id={content.confirmLineItemsButtonId?content.confirmLineItemsButtonId+"_lob":"lob"}
                                defaultValue={'DEFAULT'}
                    onChange={(e) => handleChangeLOB(e)}
                    disabled={ globalDisabled}       
                
                >

                            <option className="input-wrap-text" value="DEFAULT" >Please select geography first</option>
                            {searchKBArticles[country] && Object.keys(searchKBArticles[country]).map((item) => {
                            return ( <option className="input-wrap-text" value={item} >{item}</option>)
                            })}
                    </select>
                </div>
             
                <div className="webformInputLabel">
                    <span className="webformFieldTitle kBFields">Product Type:</span>
                </div>
                <div className="input-wrap">
                <select className="input-wrap-text kBFieldSelect" name="product" value={productType}
                    id={content.confirmLineItemsButtonId ? content.confirmLineItemsButtonId + "_product" : "product"}
                    defaultValue={'DEFAULT'}
                    onChange={(e) => handleChangeProductType(e)}
                    disabled={ globalDisabled}       
                >
                            
                            <option className="input-wrap-text" value="DEFAULT" >Please select lob first</option>
                            {searchKBArticles[country] && searchKBArticles[country][lob] && searchKBArticles[country][lob].map((item) => {
                            return ( <option className="input-wrap-text" value={item} >{item}</option>)
                            })}
                    </select>
                </div>
                
                <div className="kBFormSubmitDiv">
                    <button
                       onClick={handleSubmitClick}
                       id={content.confirmLineItemsButtonId?content.confirmLineItemsButtonId:"defaultId"}
                        className="webformButton primary kBFormSubmit"
                    disabled={!country || !lob || !productType || lob === 'DEFAULT' || productType === 'DEFAULT' || isSubmitted || globalDisabled}
                    style={{
                                cursor:!country || !lob || !productType || lob === 'DEFAULT' || productType === 'DEFAULT' || isSubmitted || globalDisabled ? 'not-allowed':'pointer'
                            }}
                >
                        Confirm</button>
                </div>
                <br/>
        </div>
    );
};

export default SearchKBFiltersHPS;