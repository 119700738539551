import "react-app-polyfill/ie11";
import "core-js/stable";
import React from "react";

const ShowImage = ({ content }) => {
  return (
    <div style={{display:'flex'}}>
       {!window.config.isNewUI && <div className="Icon"></div>}
        <div className="botTextBox imageBorder" style={{width:(content.templateType === 'large' ? '100%' : '50%'),height:(content.templateType === 'large' ? '100%' : '50%')}}>
            <img className="imageSize" alt={content.name} src={content.url} />
        </div> 
    </div>
  );
};

export default ShowImage;