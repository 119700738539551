import "react-app-polyfill/ie11";
import "core-js/stable";
import { hooks } from "botframework-webchat-component";
import React, { useCallback } from "react";
import Markdown from "markdown-to-jsx";
require("es6-promise").polyfill();
require("isomorphic-fetch");
var classNames = require("classnames");

const { useSendPostBack } = hooks;

const PostBackButton = ({
  cardAction: { title, value },
  handleFocusSendBox
}) => {
  const sendPostBack = useSendPostBack();
  //const handleClick = useCallback(() => sendPostBack(value), [value, sendPostBack]);
  const handleClick = useCallback(() => {
    handleFocusSendBox();
    sendPostBack(value);
  }, [value, sendPostBack, handleFocusSendBox]);
  let suggesteActionButton = classNames({
    suggesteActionButton: true
  });
  return (
    <button
      // Web Chat does the heavylifting for us by exposing a "sendPostBack" function.
      onClick={handleClick}
      type="button"
      className={suggesteActionButton}
    >
      <Markdown>{title}</Markdown>
    </button>
  );
};

export default PostBackButton;
