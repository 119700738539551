import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React from 'react';
import SpinnerGIf from '../images/Spinner.gif';
import Close from "../images/svgLatest/Close_blue_withoutCircle.svg";
import StaticLabels from "../util/staticLabels.json";
var classNames = require('classnames');



const Spinner = ({ isMaximised, showSPinner, handleModalChange }) => {
    var spinnerClass = classNames({
        spinnerClass: true,
        displayNone: !isMaximised || showSPinner,
    })
    var spinnerClose = classNames({
        spinnerClose: true,
        displayNone: !isMaximised || showSPinner,
    })
    return (
        <div className={spinnerClose}>
           {window.config.businessUnit !== "HCE-MARKETING" && <div >
                <a
                    href="#"
                    onClick={handleModalChange({
                        isOpenModal: false,
                        value: null,
                        target: "closeLaunchIcon"
                    })}
                >
                    <img alt="Close" title="Close Bot" src={Close}></img>
                </a>
            </div>}
            <div className={spinnerClass}>
                <div className="spinnergif">
                    <img alt="spinner" width={176} height={176} src={SpinnerGIf}></img>
                    <div style={{ position: "relative" }}>
                        {window.config.businessUnit === 'HBT-GCEGBT' ?  
                        <h1 id="spinner-text" className="spinner-text">{window?.config?.language ? StaticLabels?.[window?.config?.language]?.NowLoading : StaticLabels?.en?.NowLoading}</h1>
                        :
                        <h1 id="spinner-text" className="spinner-text">Now loading, please wait ...</h1>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Spinner;