import React, {useEffect, useState} from 'react';

var classNames = require("classnames");
function getMarketoForm(formName,on24Id){
  window.MktoForms2.loadForm("//hcenews.honeywell.com", "093-RAU-212", formName, function (form) {
          form.vals({"mKTOChatbotON24ID":on24Id});
          form.onSuccess(function(){
            return false;       
          });
          form.onSubmit(function(){
            //event.preventDefault();
            let newEvent = new Event("marketoformsubmit");
            document.dispatchEvent(newEvent);
            let element=document.getElementById("mktoForm_"+formName);
            element.remove();
            document.getElementsByClassName("workflowForm")[0].style.display = "none";
            return false;
          });
      });
}

const WorkFlow1 = ({content}) => {
    const [formId, setFormId] = useState(null);
    let formName;

    var Icon = classNames({
      Icon: true,
      IconUser: true
    });

    useEffect(() => {
      if(content.sbuId === "Connected Building") {
          formName = 2171;
          setFormId("mktoForm_2171");
      } else if(content.sbuId === "Cyber") {
          formName = 2174;
          setFormId("mktoForm_2174");
      } else {
          formName = 2173;
          setFormId("mktoForm_2173");
      }
      getMarketoForm(formName,content.on24Id);
    }, []);
      
    return (
      <div style={{display:'flex'}} className="workflowForm">
     {!window.config.isNewUI && <div className={Icon}></div>}
      <div className="botActivityClass activityClass">
        <div className="botTextBox conversationTextBox">
          <div className="conv-text">
            <form id={formId}></form>
          </div>
        </div>
      </div>
      </div>
    );
}

export default WorkFlow1;

