import "react-app-polyfill/ie11";
import "core-js/stable";
import React, { useState, useCallback, useContext } from "react";
import { hooks } from "botframework-webchat-component";
import Markdown from "markdown-to-jsx";
import setLastMessage from "../util/setLastMessage";
import StaticLabels from "../util/staticLabels.json";
import PDF from '../images/svgLatest/PDF.svg';
import LeftArrow_New from '../images/svgLatest/Left_Arrow_New.svg';
import RightArrow_New from '../images/svgLatest/Right_Arrow_New.svg';
import WEB from '../images/svgLatest/Web.svg';
import { AppContext } from "../util/appContext";
import {  getCookie } from '../util/cookieActions';
import orderDocumentDownload from '../util/documentDownloadApi';
import LoadingOverlay from 'react-loading-overlay';

var classNames = require("classnames");

const MyLink = ({ children, ...props }) => {
  return (
    <a style={{ fontSize: "14px" }} {...props}>
      {children}
    </a>
  );
};

const { useSendMessage, useSendEvent } = hooks;

const OrderDetailCard = ({ content }) => {
  var orderDetailBodytClass = classNames({
    orderDetailBody: true
    // customscrollbar: true
  });
  const [indexCount, setCount] = useState(0);
  const context = useContext(AppContext);
  const sendMessage = useSendMessage();
  const sendEvent = useSendEvent();

  const handleClick = useCallback(
    value => event => {
      event.preventDefault();
      context.setMenuOpen(false);
      if (getCookie(window.config.businessUnit + '-EnableSendBox')) {
        sendEvent('enableSendBoxEventReceived');
      }
      context.setDisableSendBox(true);
      setLastMessage(value);
      sendMessage(value);
    },
    [sendMessage]
  );

  const handleDocumentDownload = useCallback(
    content => event => {
      event.preventDefault();
      context.setOpen(true);
      context.setOverLayText('Please give me a minute while I pull the document...');
      getDocumentDownload(content);
    });

  // order status number --- Eg: 2367979
  async function getDocumentDownload(contentUrl) {
    const res = await orderDocumentDownload(contentUrl);

    if (res && res.data) {
      const newBlob = new Blob([res.data], { type: "application/octet-stream" });

      // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
      }
      else {
        // For other browsers: create a link pointing to the ObjectURL containing the blob.
        const blobUrl = window.URL.createObjectURL(newBlob);
        let link = document.createElement('a');
        link.href = blobUrl;
        const orderDetailsObject = JSON.parse(contentUrl);
        link.download = orderDetailsObject['documentFlag'] + '-' + orderDetailsObject['documentNumber'] + '.pdf';
        link.click();

        // For Firefox it is necessary to delay revoking the ObjectURL.
        setTimeout(() => { window.URL.revokeObjectURL(blobUrl); }, 250);
      }
      context.setOpen(false);
      context.setOverLayText("");
    }
    else {
      context.setOverLayText("Document not available to download. Please try later.");
      setTimeout(() => {
        context.setOpen(false);
        context.setOverLayText("");
      }, 3000);
    }
  }

  const messages = content.map((data, index) => (
    <>
      <div className="row"
        style={{
          padding: content?.length <= 1 && '24px',
        }}
      >

        {content?.length > 1 && <p 
        className="resultCounter"
        >
          {indexCount + 1} / {content?.length}{" "}
          {content[0].language
            ? StaticLabels[content[0].language.toLowerCase()].Results
            : "Results"}
        </p>}
        <div 
        className="rowContent"
        >
          {content.length > 1 && <div className="column left">
            <a href="#" onClick={() => getPreviousIdx()} >
              <img src={LeftArrow_New} alt="leftArrow" />
            </a>
          </div>}
          <div className="column center">
            <div key={index} className={"carousel-body-size"}>
              <div className="orderDetailHeader">
                <h6>{data.header}</h6>
                <div>
                  {Object.entries(data.subHeader).map((sbh, i) => (
                    <React.Fragment key={i}>
                      {i > 0 ? <p key={`${i}-break`}>/</p> : ""}
                      <p key={`${i}-header`}>{sbh[0]}</p>
                      <p key={`${i}-subheader`}>{sbh[1]}</p>
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div className={orderDetailBodytClass}>
                {data.text ? (
                  <div class="conv-text" style={{ margin: "8px" }}>
                    <Markdown
                      options={{
                        overrides: {
                          forceBlock: true,
                          forceInline: false,
                          a: {
                            component: MyLink,
                            target: "_blank",
                            props: {
                              // href:"http://youtube.com"
                              className: "markdown-link",
                              target: "_blank"
                            }
                          }
                        }
                      }}
                    >
                      {data.text.text}
                    </Markdown>
                  </div>
                ) : (
                  ""
                )}
                <table>
                  <tbody>
                    {Object.entries(data.tableContent).map((tableData, i) => (
                      (Object.keys(tableData[1]) && Object.keys(tableData[1]).length !== 0) &&
                      <tr key={i}>
                        <td>{tableData[1].name}</td>
                        <td style={{ fontWeight: tableData[1].font }}>
                          <Markdown
                            options={{
                              overrides: {
                                forceBlock: true,
                                forceInline: false,
                                a: {
                                  component: MyLink,
                                  target: "_blank",
                                  props: {
                                    // href:"http://youtube.com"
                                    className: "markdown-link",
                                    target: "_blank"
                                  }
                                }
                              }
                            }}
                          >
                            {tableData[1].text ? tableData[1].text.toString() : ""}
                          </Markdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {data.attachments && data.attachments.length > 0 &&
              <div className="documentDownload">
                {data.attachments.map((download, i) =>
                  <span>
                    {download.contentUrl !== "" ?
                      (download.buttonID ?
                        <a href="#" id={download.buttonID} onClick={handleDocumentDownload(download.contentUrl)}>
                          <img src={PDF} alt="pdf" style={{ marginRight: "10px" }} />
                          {download.name}
                        </a> :
                        <a href={download.contentUrl} target="_blank">
                          <img src={PDF} alt="pdf" style={{ marginRight: "10px" }} />
                          {download.name}
                        </a>)
                      :
                      <span className="documentWithoutAttachment"> {download.name} : N/A </span>
                    }
                    <span style={{ marginRight: "10px", marginLeft: "10px" }}>{data.attachments.length === i + 1 ? "" : "|"}</span>
                  </span>)}
              </div>
            }
            <div className="viewMoreLineitems">
              {data.buttons && (
                <button className="btn-primary viewLineLink"
                  id={data.buttons.id}
                  onClick={handleClick(data.buttons.data)}>
                  {data.buttons.title ? data.buttons.title : "Select Order"}
                </button>
              )}
              {data.link ? (
                <a href={data.link.text} target="_blank" class="btn-primary viewMoreLink goToOrderDetails">
                  <img src={WEB} alt="web" />
                  <p>{data.link.name ? data.link.name : "Go to Order Details"}</p>
                </a>
              ) : (
                ""
              )}
            </div>
            {data.footer ? (
              <div>
                <p className={"card-warn-message"}>
                  <Markdown
                    options={{
                      overrides: {
                        forceBlock: true,
                        forceInline: false,
                        a: {
                          component: MyLink,
                          target: "_blank",
                          props: {
                            // href:"http://youtube.com"
                            className: "markdown-link-footer",
                            target: "_blank"
                          }
                        }
                      }
                    }}
                  >
                    {data.footer}
                  </Markdown>
                </p>
              </div>
            ) : null}
          </div>
          {content.length > 1 && <div className="column right">
            <a href="#" onClick={() => getNextIdx()}>
              <img src={RightArrow_New} alt="rightArrow" />
            </a>
          </div>}
        </div>
      </div>
    </>

  ));
  const length = messages.length;
  const getNextIdx = () => {
    setCount((indexCount + 1) % length);
  };
  const getPreviousIdx = () => {
    setCount((indexCount == 0 && length - 1) || indexCount - 1);
  };

  return (
    <LoadingOverlay
      active={context.open}
      spinner
      text={context.overLayText}
    >
      <div className="orderDetail">
        {/* <div className={"botAvatarClass"}>
        <Components.Avatar height={20} fromUser={false} />
      </div> */}
        <div className="orderSummaryCards">
          {/* {length > 1 ? (
          <div style={{ marginBottom: "0.5rem" }}>
            <ul className="orderSummary">
              <li>
                <span className="orderSummaryButtons">
                  {indexCount + 1} of {length}{" "}
                  {content[0].language
                    ? StaticLabels[content[0].language.toLowerCase()].Results
                    : "Results"}
                </span>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )} */}
          {messages[indexCount]}
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default OrderDetailCard;
