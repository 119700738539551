import 'react-app-polyfill/ie11';
import 'core-js/stable';
// import React from 'react';
// import config from '../../public/config.json';
import getCWACookies from './getCWACookies';
import setCWACookies from './setCWACookies';

const  initCWASSOUOP = () => {
    let  accessToken = null;
   // let url = `${window.config.ssoUrl}?client_id=${window.config.clientId}&scope=${window.config.scope}&response_type=${window.config.responseType}&redirect_uri=${window.config.ssoRedirectUrl}`;
   let url= window.config.ssoUrl+"?client_id="+window.config.clientId+"&scope="+window.config.scope+"&response_type="+window.config.responseType+"&redirect_uri="+window.config.ssoRedirectUrl;
   var event = document.createEvent("Event");
   event.initEvent('botloginsuccess',false, true); 
    if (window.config.isAnonymous) {
      window.parent.postMessage({ key: 'display_chatbot', businessUnit:window.config.businessUnit,cookieUrl:window.config.cookieUrl,cookieKey:window.config["3469726563746c696e650k08"].split("&=")[1],jwtTokenName: window.config.businessUnit === "SPS-ECOMM" || window.config.isChina ? window.config.jwtTokenNameHybris:window.config.jwtTokenName,pageOrigin:window.config.pageOrigin,cardpageOrigin:"*"}, window.config.pageOrigin);
      setCWACookies("cwaSSOToken", "Anonymous", 100000);
    }
    else {
      if (window.location.href.indexOf("access_token=") > 0) {
        window.parent.postMessage({ key: 'display_chatbot', businessUnit:window.config.businessUnit,cookieUrl:window.config.cookieUrl,cookieKey:window.config["3469726563746c696e650k08"].split("&=")[1],jwtTokenName: window.config.businessUnit === "SPS-ECOMM" || window.config.isChina ? window.config.jwtTokenNameHybris:window.config.jwtTokenName,pageOrigin:window.config.pageOrigin,cardpageOrigin:"*"}, window.config.pageOrigin);
        accessToken = window.location.href.split("access_token=")[1].split('&')[0];
        let tokenExpireTime = Number(window.location.href.split("expires_in=")[1]);
        setCWACookies("cwaSSOToken", accessToken, tokenExpireTime);
      } else {
        // Implicit Grant Type Call
        try {
          window.location.href = url;
        } catch (error) {
          console.log('error', error);
        }
      }
    } 
  }

  export default initCWASSOUOP;