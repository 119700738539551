import 'react-app-polyfill/ie11';
import 'core-js/stable';
import { hooks } from 'botframework-webchat-component';
import React, {useContext, useCallback} from 'react';
import CardActionButton from './CardActionButton';
import { AppContext } from '../util/appContext';
import StaticLabels from "../util/staticLabels.json";

const { useSuggestedActions } = hooks;

// Web Chat cleaned up the suggestedActions for us.
// If the last activity is from the bot and contains "suggestedActions", Web Chat will send it to us thru "suggestedActions".
const SuggestedActions = ({handleFocusSendBox,  userLanguage}) => {
  const suggestedActions = useSuggestedActions();
  const context = useContext(AppContext);
  
  const handleClick = useCallback(() => {
    context.setMoreOptionsClick(true);
   }, []);

 const renderMoreoptionButtons = (suggestedButtonsArray) => {
    let startArray = suggestedButtonsArray.slice(0,4);
    let endArray   = suggestedButtonsArray.slice(4);
    return (
      <React.Fragment>
         {startArray.map((cardAction, index) => (
          <CardActionButton key={index} handleFocusSendBox={handleFocusSendBox} cardAction={cardAction} />
        ))}
        {context.moreOptionsClick ? 
         endArray.map((cardAction, index) => (
          <CardActionButton key={index} handleFocusSendBox={handleFocusSendBox} cardAction={cardAction} />
        ))
        :
           <CardActionButton key={15} handleClick={handleClick} handleFocusSendBox={handleFocusSendBox} 
           cardAction={{title: userLanguage ? StaticLabels[userLanguage.toLowerCase()].MoreOptions : "MORE OPTIONS",
           type: "moreActions",
           value: "MORE OPTIONS"}} />
      }
       
      </React.Fragment>
    )
  }
 
   return (
    !!suggestedActions[0].length && (
      <li>
        <div>
      <ul className="suggestedAction">
     {suggestedActions[0].length < 5 ?
     suggestedActions[0].map((cardAction, index) => (
      <CardActionButton key={index} handleFocusSendBox={handleFocusSendBox}  cardAction={cardAction} />
  ))
  :
  renderMoreoptionButtons(suggestedActions[0])
     }  
      </ul>
      </div>
      </li>
    )
  );
};

export default SuggestedActions;
