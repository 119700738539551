import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React, {useState, useCallback, useContext} from 'react';
import CaseWebformModal from './CaseWebformModal';
import { AppContext } from "../util/appContext";
import { hooks } from "botframework-webchat-component";

const classNames = require('classnames');
const { useSendMessage,useSendEvent } = hooks;

const ExitChatCard = ({content,businessUnit}) => {
    const [showArticleModal, setArticleModal] = useState(false);
    const context = useContext(AppContext);
    const sendMessage = useSendMessage();
    const sendEvent = useSendEvent();

    const articleButton = classNames({
        'btn-secondary': true,
        'article-button':true,
        'case-webform': true
    });

    let suggesteActionButton = classNames({
        suggesteActionButton: true
      });

    window.onmessage = (e) => {
        switch (e.data.key) {
          case 'display_article_popup':
            document.getElementById('root').classList.remove('root-min');
            break;
          case 'hide_article_popup':
            document.getElementById('root').classList.add('root-min');
            break;
          default:
            break;
        }
      };
      const handleClick = useCallback(
        value => event => {
          event.preventDefault();
          context.setMenuOpen(false);
          context.setHideStickyMenu(false);
          // Hiding the suggested action buttons
          var displayVariable = document.querySelectorAll(".suggestedAction");
          displayVariable.forEach(function (buttonElement, i) {
            buttonElement.style.display = "none";
          });  
          sendMessage(value);
          content.buttons = [];
          // Sellect all elements with class heroCardButton
          var heroCardButtons = document.querySelectorAll(".heroCardButton");
          // Apply CSS property to it
          heroCardButtons.forEach(function (heroCardButton, i) {
            heroCardButton.style.border = "none";
          });  
        },
        [sendMessage]
      );
    const handleArticleModalSubmit = useCallback((event, comments, communicationMode, phoneNumber) => {
        context.setMenuOpen(false);
        context.setHideStickyMenu(false);
         // Hiding the suggested action buttons
         var displayVariable = document.querySelectorAll(".suggestedAction");
         displayVariable.forEach(function (buttonElement, i) {
           buttonElement.style.display = "none";
         });  
        if(showArticleModal){
            window.parent.postMessage({key:'display_article_popup', origin: window.location.origin}, window.config.pageOrigin);
        }else {
            window.parent.postMessage({key:'hide_article_popup', origin: window.location.origin}, window.config.pageOrigin);
        }
        setArticleModal(!showArticleModal);
        sendEvent('webformPopupSubmit', {webformComment: comments, preferredCommunicationMode: communicationMode + " " +phoneNumber});
    },[showArticleModal,setArticleModal]);

    const handleArticleModalClose = useCallback( event => {
        context.setMenuOpen(false);
        context.setHideStickyMenu(false);
        if(showArticleModal){
            window.parent.postMessage({key:'display_article_popup', origin: window.location.origin}, window.config.pageOrigin);
        }else {
            window.parent.postMessage({key:'hide_article_popup', origin: window.location.origin}, window.config.pageOrigin);
        }
       
        setArticleModal(!showArticleModal);

    },[showArticleModal,setArticleModal]);

    return(
        <div className="suggestedAction" id = "suggestedActionId">
          {
           businessUnit!="PMT-AMRC" &&  businessUnit!="PMT-UOP" ?  <button onClick={handleArticleModalClose} className={suggesteActionButton}>{content.Text }</button>:null
          }
          {
            window.config.isFirelitePage ? <button onClick={handleClick(content.Exit)} className={suggesteActionButton}>{content.Exit}</button>
            : <button onClick={handleClick(content.ChatWithMe)} className={suggesteActionButton}>{content.ChatWithMe}</button>
          }
        {showArticleModal?<CaseWebformModal article={content} handleArticleModalClose={handleArticleModalClose} handleArticleModalSubmit={handleArticleModalSubmit} />:null}
        </div>
    )
}

export default ExitChatCard;
