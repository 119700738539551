import React, { createContext, useState } from "react";


// const contextObj = {
//     usersCount,
//     setUsersCount
// }

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
    const modalPropsObj = { refresh: false, close: false, quitAgentQueue: false, busyNotification: false }
    const menuItemsArray = [];
    const [usersCount, setUsersCount] = useState(0);
    const [quitAgentQueue, setQuitAgentQueue] = useState(false);
    const [busyNotification, setBusyNotification] = useState(false);
    const [liveAgentName, setLiveAgentName] = useState(false);
    const [modalProps, setModalProps] = useState(modalPropsObj);
    const [showHeaderFooter, setShowHeaderFooter] = useState(false);
    const [showSendBox, setShowSendBox] = useState(true);
    const [disableSendBox, setDisableSendBox] = useState(true);
    const [enableSendBoxHBT, setEnableSendBoxHBT] = useState(false);
    const [enableScroll,setEnableScroll] = useState(false);
    const [showSpinnerWheel, setShowSpinnerWheel] = useState(false);
    const [surveyLink, setSurveyLink] = useState("");
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuItems, setMenuItems] = useState(menuItemsArray);
    const [optionSelected, setOptionSelected] = useState(false);
    const [hideStickyMenu, setHideStickyMenu ] = useState(false);
    const [showGoBackbutton, setShowGoBackbutton] = useState(true);
    const [launcherIcon, setLauncherIcon] = useState(false);
    const [webformContent, setWebformContent] = useState({});
    const [showSurvey, setShowSurvey] = useState(false);
    const [startingMessage, setStartingMessage] = useState(null);
    const [workflowId, setWorkflowId] = useState(null);
    const [pageRuleId, setPageRuleId] = useState(null);
    const [pageId, setPageId] = useState(null);
    const [priority, setPriority] = useState(null);
    const [minimizeBot, setMinimizeBot] = useState(false);
    const [sendWelcomeCard, setSendWelcomeCard] = useState(true);
    const [showArticleModal, setArticleModal] = useState(false);
    const [articlePopUp, setArticlePopUp] = useState(false);
    const [pageTitleCookie, setPageTitleCookie] = useState(null);
    const [pageLanguage, setPageLanguage] = useState(null);
    const [countrySelectedOnPortal, setCountrySelectedOnPortal] = useState(null);
    const [checkLiveAgentAvailable, setCheckLiveAgentAvailable] = useState(false);
    const [isLiveAgent, setLiveAgent] = useState(false);
    const [overLayText,setOverLayText] = useState("");
    const [open,setOpen] = useState(false);
    const [restrictTextBox,setRestrictTextBox] = useState(false);
    const [showCaseWebFormArticleModal, setCaseWebFormArticleModal] = useState(false);
    const [moreOptionsClick, setMoreOptionsClick] = useState(false);
    const [lastTimestamp, setLastTimestamp] = useState("");
    const [isAccountChanged,setIsAccountChanged] = useState(false);
    const [transcriptTranslationRequired, setTranscriptTranslationRequired] = useState(false);
    const [liveAgentDropDownPreferred, setLiveAgentDropdownLanguagePreferred] = useState("");

    const contextObj = { 
        usersCount, setUsersCount, 
        quitAgentQueue, setQuitAgentQueue,
        busyNotification, setBusyNotification, 
        surveyLink, setSurveyLink, 
        liveAgentName, setLiveAgentName, 
        modalProps, setModalProps, 
        showHeaderFooter, setShowHeaderFooter,
        showSendBox, setShowSendBox,
        disableSendBox, setDisableSendBox,
        enableSendBoxHBT, setEnableSendBoxHBT,
        enableScroll,setEnableScroll,
        showSpinnerWheel, setShowSpinnerWheel,
        menuOpen, setMenuOpen,
        menuItems, setMenuItems,
        optionSelected, setOptionSelected,
        hideStickyMenu, setHideStickyMenu,
        showGoBackbutton, setShowGoBackbutton,
        launcherIcon, setLauncherIcon,
        webformContent, setWebformContent,
        showSurvey, setShowSurvey,
        startingMessage, setStartingMessage,
        workflowId, setWorkflowId,
        pageRuleId, setPageRuleId,
        pageId, setPageId,
        priority, setPriority,
        minimizeBot, setMinimizeBot,
        sendWelcomeCard, setSendWelcomeCard,
        showArticleModal, setArticleModal,
        articlePopUp, setArticlePopUp,
        pageTitleCookie, setPageTitleCookie,
        pageLanguage, setPageLanguage,
        countrySelectedOnPortal, setCountrySelectedOnPortal,
        checkLiveAgentAvailable, setCheckLiveAgentAvailable,
        isLiveAgent, setLiveAgent,
        overLayText,setOverLayText,
        open,setOpen,
        restrictTextBox,setRestrictTextBox,
        showCaseWebFormArticleModal,setCaseWebFormArticleModal,
        moreOptionsClick, setMoreOptionsClick,
        lastTimestamp, setLastTimestamp,
        isAccountChanged, setIsAccountChanged,
        transcriptTranslationRequired, setTranscriptTranslationRequired,
        liveAgentDropDownPreferred, setLiveAgentDropdownLanguagePreferred
    }

    return (
        <AppContext.Provider value={contextObj}>
            {children}
        </AppContext.Provider>
    );
};

export { AppContext, AppContextProvider };