import React from 'react';
import { hooks } from "botframework-webchat-component";

const { useSendMessage } = hooks;

const HCEMarketingWorkflow3Card = ({content}) => {
    const sendMessage = useSendMessage();

    const handleImageClick = (industryName) => {
        sendMessage(industryName);
        document.getElementsByClassName("workflowImages")[0].style.display = "none";
    };

    const renderWorkflowImages = () => {
        return content.card.map((element, index) => {
            return <figure className="figureStyle" key={index} onClick={() => handleImageClick(content.card[index].name)}>
                        <img className="figureImage" src={element.guidingPostTemplate.industryImageUrl} />
                        <figcaption className="figureCaption">{element.name}</figcaption>
                   </figure>
        });
    };

    return (
        <div className="workflowImages">
            {renderWorkflowImages()}
        </div>
    );
}

export default HCEMarketingWorkflow3Card;