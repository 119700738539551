import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React from 'react';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import close from '../images/svgLatest/Close.svg';
var classNames = require('classnames');



const NewArticleModal = ({article, handleArticleModalClose}) => {

    var closeButton = classNames({
        'btn-primary':true,
         'article-popup-close':true,
    })
    var veiwArticle = classNames({
        'btn-primary':true,
        'viewFullArticle':true
    })

    return(
        <div>
            <Modal
                isOpen={true}
                ariaHideApp={false}
                id="article"
            >
                <div className="pop-up-close-container">
                    <img alt="Close" onClick={handleArticleModalClose(0)} style={{cursor:'pointer'}} src={close} />
                </div>

                <div className="article-popup-container">
                    <div>
                        <h1>{article.Title.text}</h1>
                    </div>
                    {article.ArticleNumber ? <div>
                        <span>{article.ArticleNumber.fieldName}</span>
                        {parse(article.ArticleNumber.text)}
                    </div> : null}
                    {article.Question ? <div>
                        <span>{article.Question.fieldName}</span>
                        {parse(article.Question.text)}
                    </div> : null}
                    {article.Environment ? <div>
                        <span>{article.Environment.fieldName}</span>
                        {parse(article.Environment.text)}
                    </div> : null}
                    {article.Answer ? <div>
                        <span>{article.Answer.fieldName}</span>
                        {parse(article.Answer.text)}
                    </div> : null}
                    {article.AppliesTo ? <div>
                        <span>{article.AppliesTo.fieldName}</span>
                        {parse(article.AppliesTo.text)}
                    </div> : null}
                    {article.CaseNumber  ? <div>
                        <span>{article.CaseNumber.fieldName}</span>
                        {parse(article.CaseNumber.text)}
                    </div> : null}
                    {article.Date  ? <div>
                        <span>{article.Date.fieldName}</span>
                        {parse(article.Date.text)}
                    </div> : null}
                    {article.CaseStatus  ? <div>
                        <span>{article.CaseStatus.fieldName}</span>
                        {parse(article.CaseStatus.text)}
                    </div> : null}
                    {article.Resolution ? <div>
                        <span>{article.Resolution.fieldName}</span>
                        {parse(article.Resolution.text)}
                    </div> : null}
                    {article.Description ? <div>
                        <span>{article.Description.fieldName}</span>
                        {parse(article.Description.text)}
                    </div> : null}
                    {article.ApplicationGroup ? <div>
                        <span>{article.ApplicationGroup.fieldName}</span>
                        {parse(article.ApplicationGroup.text)}
                    </div> : null}
                    {article.Summary  ? <div>
                        <span>{article.Summary.fieldName}</span>
                        {parse(article.Summary.text)}
                    </div> : null}
                </div>
                {article.CommunityUrl ? <div style={{ margin: "50px 20px" }}>
                        {article.CommunityUrl.text ? <a href={article.CommunityUrl.text} className={veiwArticle} rel="noopener noreferrer" target="_blank" style={{ padding: "15px" }}>
                        {window.config.sbg === "PMT" && window.config.gbe === "UOP" && article.isServiceRequestButton ? 'View full Service Request' : 'VIEW FULL ARTICLE'}
                            
                            </a> : ""}
                    </div> : null
                }
                 {article.Url  ? <div style={{ margin: "50px 20px" }}>
                        {article.Url.text ? <a href={article.Url.text} className={veiwArticle} rel="noopener noreferrer" target="_blank" style={{ padding: "15px" }}>
                        {window.config.sbg === "PMT" && window.config.gbe === "UOP" && article.isServiceRequestButton ? 'View full Service Request' : 'VIEW FULL ARTICLE'}
                            </a> : ""}
                    </div> : null
                }

            </Modal>
        </div>
    );
}

export default NewArticleModal;