import 'react-app-polyfill/ie11';
import 'core-js/stable';
//import React from   'react';
import axios from 'axios';
import { getCookie } from '../util/cookieActions';
import qs from 'qs'
// import config from "../config.json";
// import config from "../../public/config.json";

export const getToken = async () => {
  let res;
  if(window.config.isNewTokenStrategy){
    res = await axios.post(window.config["gatewayUrl"]+"ui/directlinetoken?code="+window.config["d4e13c296cf1fd10beca8bfa4e804bc4"]?.split("&=")[1],
      { "businessUnit": window.config.businessUnit, "isAEM": window.config.isAEM ? true : false,"isChina":window.config.isChina,"isFAQ2.0":window.config.isFAQ2 ,"isDev":window.config.isDev},
      {
        headers:
          {'Content-Type': 'application/json' }
    });
    console.log(res,"..res")
  }
  return res;
}
export const sendPrivacyNotAccepted = async () => {
  if (!window.config.isChina) {
    let res;
    res = await axios.post(window.config["gatewayUrl"] + "ui/privacyfunction",
      {
        pageUrl: localStorage.getItem("pageUrl"),
        value: new Date(),
        sbg: window.config.sbg,
        gbe: window.config.gbe,
      },
      {
        headers:
          { 
            'Content-Type': 'application/json',
            'x-api-key':window.config["f3a7b1c4e8d2a0b1c6e9f8a4d7c2e1f5"]?.split("&=")[1]
          }
      });
    console.log(res, "..res")
    return res;
  }
  else {
    return null;
  }
}


export const getRefreshToken = async () => {
  const res = await axios.post('https://directline.botframework.com/v3/directline/tokens/refresh', {}, { headers: { 'Authorization': `Bearer ${getCookie(window.config.businessUnit + '-Token')}`, 'Cache-Control': 'no-cache,no-store', 'Expires': 0, 'Pragma': 'no-cache' } });
  return res;
}


export const getSuggestedInput = async (searchValue, appInsights) => {
 
  const url =window.config["gatewayUrl"]+"ui/search?code="+window.config["d4e13c296cf1fd10beca8bfa4e804sear4ch"]?.split("&=")[1]

  const requestBody = {
    "suggesterName" : window.config["3349726563746c696e650e07c17c22c"].split("&=")[1],
    "indexName": window.config["5rte2b5d0a4f9dfe9f3b7a88c17c55r"].split("&=")[1],
    "apiVersion": window.config.apiVersion,
    "searchServiceName":window.config["5589726563746c696e650e07c17c55e"]?.split("&=")[1],
    "searchValue": searchValue,
    "businessUnit": window.config.businessUnit,
    "searchServiceAPiKey": (window.config.businessUnit === 'PMT-HPS' || window.config.businessUnit === 'SPS-ECOMM')?window.config.searchServiceApi.split("&=")[1]: window.config["7f3e2b5d0a4f9dfe9f3b7a88c17c66d"]?.split("&=")[1],
  };

  try {
    const response = await axios.post(url, requestBody, {
      headers:
        {'Content-Type': 'application/json' }
  });
    if (response.status !== 200) {
      console.log('Unexpected response status:', response.status);
      return null; 
    }
    return response.data; //function will return value directly in body.
  } catch (error) {
    console.log('error at autosuggestion:', error);

    if (error.response) {
      appInsights.trackTrace({ message: "Exceptions", properties: { name: "Suggested input error", message: error.message, stack: error.stack } });
      return null;
    };
  }
}