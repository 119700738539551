import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React, {useState} from 'react';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import close from '../images/svgLatest/Close.svg';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const CaseWebformModal = ({article, handleArticleModalClose, handleArticleModalSubmit}) => {
   const [comments, setComments] = useState("");
   const [communicationMode, setCommunicationMode] = useState("E-mail");
   const [phoneNumber, setPhoneNumber] = useState("");

   const renderCaseWebformField = (fieldName,fieldValue,requireField) => {
        return (
            <div>
                <div className="webformInputLabel">
                    <span className="webformFieldTitle">{fieldName}</span>
                    {requireField && <div className="webformBadgeCircular"></div>} 
                </div>
                <div className="input-wrap">
                    <input type="text" className="input-wrap-text input-disabled" value={fieldValue} disabled />
                </div>
            </div>
        )
    }

    const renderCommunicationModeOptions = (options) => {
        return (
            <>
            {options.map(element => <option  className="input-wrap-text" value={element}>{element}</option> )}
            </>
        )
    }

    return(
        <div>
            <Modal
                isOpen={true}
                ariaHideApp={false}
                id="article"
            >
                <div className="webform-popup-close-container">
                    <h1>{article.Title}</h1>
                   {window.config.businessUnit === "PMT-UOP"?null:<img alt="Close" onClick={handleArticleModalClose} style={{cursor:'pointer'}} src={close} />}
                </div>
                <div className="webformContainer">
                    {article.CaseNumber && renderCaseWebformField(article.CaseNumber.fieldName, parse(article.CaseNumber.text), false)}
                    {article.CaseReason && renderCaseWebformField(article.CaseReason.fieldName, parse(article.CaseReason.text), false)}
                    {article.Subject && renderCaseWebformField(article.Subject.fieldName, parse(article.Subject.text), false)}
                    {article.Status && renderCaseWebformField(article.Status.fieldName, parse(article.Status.text), false)}
                    {article.SubStatus && renderCaseWebformField(article.SubStatus.fieldName, parse(article.SubStatus.text), false)}
                    {article.CreatedDate && renderCaseWebformField(article.CreatedDate.fieldName, parse(article.CreatedDate.text), false)}
                    {article.Description && renderCaseWebformField(article.Description.fieldName, parse(article.Description.text), false)}
                    {article.CommunicationMode && <div>
                        <div className="webformInputLabel">
                            <span className="webformFieldTitle">{article.CommunicationMode.fieldName}</span>
                        </div>
                        <div className="input-wrap">
                            <select className="input-wrap-text"  value={communicationMode} onChange={e => { setCommunicationMode(e.target.value); setPhoneNumber("");}}>
                                {renderCommunicationModeOptions(article.CommunicationMode.options)}
                            </select>
                        </div>
                    </div>}
                    {article.Comments && <div className={window.config.businessUnit === "PMT-UOP"? "commentsContainer" : null}>
                        <div className="webformInputLabel">
                            <span className={(window.config.businessUnit === "PMT-UOP" || window.config.businessUnit === "SPS-ECOMM" )? "webformFieldCommentsTitle" : "webformFieldTitle"}>
                                {article.Comments.fieldName}</span>
                        </div>
                        <div className="input-wrap">
                            <textarea  placeholder="Please type comments here" rows="6" maxLength="300"  className="input-wrap-text" onChange={e => setComments(e.target.value)} value={comments} />
                        </div>
                        {window.config.businessUnit === "PMT-UOP" && <div class="below-text">
                            <span class="ui helper-message">Minimum 10 characters should entered.</span>
                        </div>}  
                        {window.config.businessUnit === "SPS-ECOMM" && <div class="below-text">
                            <span class="ui helper-message">Minimum 15 characters should entered.</span>
                        </div>} 
                    </div>}
                    {(communicationMode === "Phone" || communicationMode === "SMS") && <div>
                        <div className="webformInputLabel">
                            <span className="webformFieldCommentsTitle">Contact Number</span>
                        </div>
                        <div className="input-wrap">
                            <PhoneInput className="input-wrap-text"
                                placeholder="Enter phone number"
                                value={phoneNumber}
                                limitMaxLength = {true}
                                onChange={setPhoneNumber} />
                        </div>
                    </div>}
                </div>
                <div className="webformActions">
                    {article.buttons && article.buttons.length > 0 &&
                        article.buttons.map(item => {
                            if (item.button.type === 'Articlebutton') {
                                return <button onClick={(e) => handleArticleModalSubmit(e, comments, communicationMode, phoneNumber)}
                                                className="webformButton primary"
                                                disabled={(!phoneNumber && (communicationMode === "Phone" || communicationMode === "SMS")) || (window.config.businessUnit === "PMT-UOP" && comments.length < 10) || (window.config.businessUnit === "SPS-ECOMM" && comments.length < 15)} >
                                        {item.button.title}</button>
                            }
                            if (item.button.type === 'Cancelbutton') {
                                return <button onClick={handleArticleModalClose} className="webformButton secondary">{item.button.title}</button>
                            }
                        })}
                </div>
            </Modal>
        </div>
    );
}

export default CaseWebformModal;