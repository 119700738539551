import 'react-app-polyfill/ie11';
import 'core-js/stable';
// import React from 'react';

const setCWACookies = (name, value, days) => {
  sessionStorage.setItem(name,value);
    // var expires = '';
  
    // if (days) {
    //   var date = new Date();
    //   //SSO Expiry set to 1min
    //   date.setTime(date.getTime() + days * 1000);
    //   expires = '; expires=' + date.toUTCString();
    // }
    // document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  export default setCWACookies;