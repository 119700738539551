import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React,{ useContext, useCallback} from 'react';
import Modal from 'react-modal';
import StaticLabels from '../util/staticLabels.json';
import { hooks } from "botframework-webchat-component";
import BackMenu_HBT from '../images/svgLatest/BackMenu_HBT.svg';
import { AppContext } from '../util/appContext';
import {  getCookie, eraseCookie } from '../util/cookieActions';
const classNames = require('classnames');
const { useSendEvent } = hooks;
  

 const ChatModal = ({handleModalChange, handleRefreshModalPopup, handleCloseModalPopup, handleMaximizeChange, isOpenModal, refresh,close,userLanguage}) =>{

   const context = useContext(AppContext);
   
    const confirmButton = classNames({
      restartYesButton:true,
      'btn-primary':true,
      'restartNew_HBT' : (window.config.isNewUI && context.modalProps.refresh) ? true : false
    });
    const cancelButton = classNames({
      restartNoButton:true,
      'btn-secondary':true,
      'restartNew_HBT' : (window.config.isNewUI && context.modalProps.refresh) ? true : false
    });

    let customStyles;
    if(window.config.isNewUI && context.modalProps.refresh) {
      customStyles = {
        content : {
          top: 'auto',
          left: 'auto',
          right: 'auto',
          bottom: '0',
          width: '100%',
          borderRadius: '8px',
          border: '1.5px solid #d0d0d0',
          padding: '9px 20px',
          animation: '1s appear forwards'
        }
      };
    }
    else {
      customStyles = {
        content : {
          top: '45%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)'
        }
      };
    }
     
    const sendMessage = useSendEvent();

    const handleRefreshYes = useCallback(event => {
        if(getCookie(window.config.businessUnit + '-adjustResize') && window.config.isAdjustable==true) {
          eraseCookie(window.config.businessUnit + '-adjustResize');
        }
        sendMessage('updateTranscriptAndResolveCase',{sendRefreshEvent: true});
        handleRefreshModalPopup();
      },
      [sendMessage,handleRefreshModalPopup]
    );


    const handleCloseYes = useCallback(event => {
      if(getCookie(window.config.businessUnit + '-adjustResize') && window.config.isAdjustable==true) {
        eraseCookie(window.config.businessUnit + '-adjustResize');
      }
      sendMessage('updateTranscriptAndResolveCase');
      sendMessage("CancelSession");
      handleCloseModalPopup();
      handleMaximizeChange();
    },
    [sendMessage, handleCloseModalPopup, handleMaximizeChange]
  );

      
    return(
        <Modal
        isOpen={isOpenModal}
        onRequestClose={handleModalChange(false)}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Bot Modal"
        id="main2"
        shouldCloseOnOverlayClick = {false}
       
        parentSelector={
          () => {
           return document.getElementById('main')}
        /* Function that will be called to get the parent element
           that the modal will be attached to. */}
      > 
         {
           context.modalProps.refresh && window.config.isNewUI && (
            <div className="restartContainer">
              <div className='restartHeader_HBT'>
                <img alt="Back" src={BackMenu_HBT} 
                  style={{cursor : 'pointer'}}
                  onClick={handleModalChange({
                    isOpenModal: false,
                    value: "No",
                    target: "refresh",
                    backMenu : true
                  })} />
                <h4 className="restartHeader restartText_HBT" >
                  {userLanguage
                    ? StaticLabels[userLanguage.toLowerCase()].RestartChat
                    : "Restart Chat"} </h4>
              </div>
              <p style={{ textAlign: 'left', marginLeft : '0.7rem' }} className="restartParagraph">
                {userLanguage
                  ? StaticLabels[userLanguage.toLowerCase()].RestartChatBody
                  : "Are you sure you want to restart? This will clear the memory about your conversation so far."}
              </p>
              <div className="restartButtonContainer" style={{ justifyContent: 'left', marginTop: '2rem' }}>
                <button
                  className={cancelButton}
                  onClick={handleModalChange({
                    isOpenModal: false,
                    value: "No",
                    target: "refresh"
                  })}
                >
                   {userLanguage
                  ? StaticLabels[userLanguage.toLowerCase()].Cancel
                  : "Cancel"}
                </button>
                <button
                  className={confirmButton}
                  onClick={handleRefreshYes}
                >
                   {userLanguage
                    ? StaticLabels[userLanguage.toLowerCase()].RestartChat
                    : "RESTART CHAT"}
                </button>
              </div>
            </div>
           )
         }
         {
           context.modalProps.refresh && !window.config.isNewUI && (
            <div className="restartContainer">
            <h2 className="restartHeader">
              {userLanguage
                ? StaticLabels[userLanguage.toLowerCase()].StartFreshConversation
                : "Do you want to start fresh conversation?"}
            </h2>
            <p className="restartParagraph">
              {userLanguage
                ? StaticLabels[userLanguage.toLowerCase()].ClearConversation
                : "This will clear my memory about your conversation so far."}
            </p>
            <div className="restartButtonContainer">
              <button
                className={cancelButton}
                onClick={handleModalChange({
                  isOpenModal: false,
                  value: "No",
                  target: "refresh"
                })}
              >
                {userLanguage
                  ? StaticLabels[userLanguage.toLowerCase()].No
                  : "No"}
              </button>
              <button
                className={confirmButton}
                onClick={handleRefreshYes}
              >
                {userLanguage
                  ? StaticLabels[userLanguage.toLowerCase()].Yes
                  : "Yes"}
              </button>
            </div>
            </div>
           )
         } 
         {
           context.modalProps.close && (
            <div className="restartContainer">
            <h2 className="restartHeader">
              {userLanguage
                ? StaticLabels[userLanguage.toLowerCase()].EndConversation
                : "Do you wish to end your conversation?"}
            </h2>
            <p className="restartParagraph">
              {userLanguage
                ? StaticLabels[userLanguage.toLowerCase()].ClearAndCloseConversation
                : "This will clear the memory about your conversation so far and close the bot."}
            </p>
            <div className="restartButtonContainer">
              <button
                className={cancelButton}
                style = {{padding: "15px 30px"}}
                onClick={handleModalChange({
                  isOpenModal: false,
                  value: "No",
                  target: "close"
                })}
              >
                {" "}
                {userLanguage
                  ? StaticLabels[userLanguage.toLowerCase()].Cancel
                  : "Cancel"}
              </button>
              <button
                className={confirmButton}
                style = {{padding: "15px 30px"}}
                onClick={handleCloseYes}
              >
                {userLanguage
                  ? StaticLabels[userLanguage.toLowerCase()].Confirm
                  : "Confirm"}
              </button>
            </div>
            </div>
           )
         }
         {/* {
          context.modalProps.quitAgentQueue && (
            <div className="restartContainer">
            <h2 className="restartHeader" style={{textAlign:'center'}} >Do you wish to end your conversation?</h2>
            <div className="restartButtonContainer">
            <button className={cancelButton}  onClick={handleModalChange({isOpenModal:false,value:'No',target:"QuitAgentQueue"})}>Cancel</button>
            <button className={confirmButton} onClick={handleModalChange({isOpenModal:false,value:'Yes',target:"QuitandCreateTicket"})}>Quit and Create Ticket</button>
            </div>
            </div>
           )
         } */}
        {
          context.modalProps.busyNotification && (
            <div className="restartContainer">
              <h1 className="restartHeader">
                {context?.isAccountChanged ?
                  userLanguage
                  ? StaticLabels[userLanguage.toLowerCase()].AccountChanged : "Account Changed"
                  :
                  userLanguage
                  ? StaticLabels[userLanguage.toLowerCase()].SessionEnded : "Session Timeout"}
            </h1>
              <p className="restartParagraph">
                {context?.isAccountChanged ?
                  userLanguage
                  ? StaticLabels[userLanguage.toLowerCase()].AccountChangeMessage : "You've changed your account. Please choose ok to clear all previous conversations and restart the conversation."
                  :
                  userLanguage
                  ? StaticLabels[userLanguage.toLowerCase()].SessionEndMessage : "You're being timed out due to inactivity. Please choose ok to clear all previous conversations and close the bot."}
            </p>
            <div className="restartButtonContainer">
              <button
                className={confirmButton}
                style = {{padding: "15px 30px"}}
                onClick={handleCloseYes}
                >
                  {userLanguage
                  ? StaticLabels[userLanguage.toLowerCase()].Okay : "Ok"}
                
              </button>
            </div>
            </div>
           )
        }
         
        </Modal>
    )
  }

  export default ChatModal; 