import "react-app-polyfill/ie11";
import "core-js/stable";
import React, { useState, useCallback, useContext } from "react";
import { hooks } from "botframework-webchat-component";
import { AppContext } from "../util/appContext";
import { getCookie} from '../util/cookieActions';

var classNames = require("classnames");

const MyLink = ({ children, ...props }) => {
  return (
    <a style={{ fontSize: "14px" }} {...props}>
      {children}
    </a>
  );
};

const { useSendPostBack, useSendMessage, useSendEvent } = hooks;

const KBFilters = ({ content, store }) => {
  var orderDetailBodytClass = classNames({
    orderDetailBody: true
  });
  const [productType, setProductType] = useState('');
  const [deviceModel, setDeviceModel] = useState('');
  const [customIssueDetailDisplay, setCustomIssueDetailDisplay] = useState(false);
  const [issueCategory, setIssueCategory] = useState('');
  const [customIssueDetail, setCustomIssueDetail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const context = useContext(AppContext);
  const sendPostBack = useSendPostBack();
  const sendMessage = useSendMessage();
  const sendEvent = useSendEvent();

  const onClick = useCallback(
    value => event => {
      event.preventDefault();
      setIsSubmitted(true);
      sendPostBack(`${productType}-${deviceModel}-${issueCategory}-${customIssueDetail}`);
      context.setMenuOpen(false);
      if(getCookie(window.config.businessUnit + '-EnableSendBox')) {
        sendEvent('enableSendBoxEventReceived');
      }
      context.setDisableSendBox(true);
    }, [
      sendPostBack,
      productType,
      deviceModel,
      issueCategory,
      customIssueDetail,
      setIsSubmitted,
    ]
  );
  const onChange = useCallback(
    value => event => {
      event.preventDefault();
      switch (event.target.id) {
        case 'product_type':
          setProductType(event.target.value);
          break;
        case 'device_model':
          setDeviceModel(event.target.value);
          break;
        case 'issue_category':
          setIssueCategory(event.target.value);
          if (event.target.value === 'other') {
            setCustomIssueDetailDisplay(true);
          } else {
            setCustomIssueDetailDisplay(false);
          }
          break;
        case 'custom_issue_detail':
          setCustomIssueDetail(event.target.value);
          break;
      }

      context.setMenuOpen(false);
      if(getCookie(window.config.businessUnit + '-EnableSendBox')) {
        sendEvent('enableSendBoxEventReceived');
      }
      context.setDisableSendBox(true);
    }, [
      setCustomIssueDetail,
      setDeviceModel,
      setProductType,
      setIssueCategory,
      setCustomIssueDetailDisplay,
    ]
  );
const  staticContent = {
    header: 'Header',
    inputs: [
      {
        type: 'select',
        label: 'Product Type',
        for: 'product_type',
        dependent: true,
        value: '',
        options: [
          {
            display: 'Please select Product Type',
            value: '',
          },
          {
            display: 'Scanners', 
            value: 'Scanners'
          },
          {
            display: 'Printer',
            value: 'Printer'
          },
          {
            display: 'Mobile Device', 
            value: 'Mobile Device'
          },
          {
            display: 'Other: Gas detection, PPE, thermostat',
            value: 'Other: Gas detection, PPE, thermostat'
          }
        ],
        id: "product_type",
        name: 'product_type',
        hidden: false,
        autoComplete: false,
      },
      {
        type: 'text',
        label: 'What is the model of your device?',
        for: 'device_model',
        placeholder: 'Device Model',
        value: '',
        id: "device_model",
        name: 'device_model',
        hidden: false,
        autoComplete: false,
      },
      {
        type: 'select',
        label: 'Issue Category',
        for: 'issue_category',
        value: '',
        onSelect: true,
        options: [
          {
            display: 'Please select issue category',
            value: ''
          },
          {
            display: 'Not turning on, not responding',
            value: 'Not turning on, not responding'
          },
          {
            display: 'Error message',
            value: 'Error message'
          },
          {
            display: 'Need to configure',
            value: 'Need to configure'
          },
          {
            display: 'Other',
            value: 'other'
          }
        ],
        id: "issue_category",
        name: 'issue_category',
        hidden: false,
        autoComplete: false,
      },
      {
        type: 'text',
        label: 'Please share your issue details',
        for: 'custom_issue_detail',
        placeholder: 'Issue details',
        value: '',
        id: "custom_issue_detail",
        name: 'custom_issue_detail',
        hidden: true,
        autoComplete: false,
      },
    ],
  };

  const rows = [];
  for (let input of staticContent.inputs) {
    let element;
    switch (input.type) {
      case 'text':
        element = <input autoComplete={input.autoComplete ? "on" : "off"} placeholder={input.placeholder} type={input.hidden && !customIssueDetailDisplay  ? 'hidden' : input.type} id={input.id} name={input.name} />;
        break;
      case 'select':
        const options = [];
        for (let option of input.options) {
          options.push(<option value={option.value}>{option.display}</option>);
        }
        element = <select autoComplete={input.autoComplete ? "on" : "off"} id={input.id} onChange={onChange(input)} placeholder={input.placeholder}>
          {options}
        </select>;
        break;
    }
    rows.push(<p className={input.hidden && !customIssueDetailDisplay ? 'hide' : ''}>
      <label for={input.for}>
        <span>{input.label}</span>
      </label><br/>
      {element}
      <br /><br />
    </p>);
  }
  return (
    <div>
      <ul className="suggestedAction">
        <div class="botTextBox conversationTextBox">
          <div class="conv-text">
        {rows}
            <button className={!isSubmitted ? '' : 'hide'} type="button" onClick={onClick()}>Submit</button>
          </div>
        </div>
      </ul>
    </div>
  );
};

export default KBFilters;
