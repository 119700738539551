import "react-app-polyfill/ie11";
import "core-js/stable";
import React, { useState, useCallback, useEffect, useRef,useContext } from "react";
import { hooks } from "botframework-webchat-component";
import Select from "react-select";
import { AppContext } from "../util/appContext";
import { getCookie } from "../util/cookieActions";
const { useSendPostBack } = hooks;

const SearchKBFiltersADM = ({ content, store }) => {
    const searchKBArticles = content && content.isLACFilters ? require('../util/searchKBArticlesLACADM.json'):require('../util/searchKBArticlesADM.json');
    const context = useContext(AppContext);
    const [region, setRegion] = useState(content && content.region ? { value: content.region, label: content.region } : null);
    const [lob, setLob] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const sendPostBack = useSendPostBack();
    const regionOptionList = useRef([]);
    const lobOptionList =  useRef([]);
    const [globalDisabled,setGlobalDisabled] = useState(false)
     
    if(searchKBArticles) {
        regionOptionList.current = [];
        Object.keys(searchKBArticles).forEach((item) => {
            regionOptionList.current.push({ value: item, label: item });
        });
    }

    if(region) {
        lobOptionList.current = [];
        if(searchKBArticles[region.value] && searchKBArticles[region.value].length !== 0) {
            searchKBArticles[region.value].map((item) => {
                return lobOptionList.current.push({ value: item, label: item });
            });
        } else {
            lobOptionList.current.push({ value: '', label: 'Not Applicable' });
            setLob({ value: '', label: 'Not Applicable' });
        }
    }

    useEffect(() => {
        let checkHistory = getCookie(window.config.businessUnit + '-CheckOrderLob');
        if(checkHistory){
            checkHistory=JSON.parse(checkHistory); 
        let isExisting = Array.isArray(checkHistory) && checkHistory?.map(i => i?.id)?.includes(content?.confirmLineItemsButtonId) 
        if (isExisting) {
            let found = checkHistory?.find(i => i?.id === content?.confirmLineItemsButtonId)
            setGlobalDisabled(true);
            if(found && found?.region) setRegion({ value: found?.region, label: found?.region });
            if(found && found?.lob) setLob( { value: found?.lob, label: found?.lob });
        }
        }
    },[]);

    // Handle change region dropdown option
    const handleChangeRegion = useCallback(
        (e) => {
            setRegion(e);
            setLob("");
            lobOptionList.current = [];
            if(searchKBArticles[e.value] && searchKBArticles[e.value].length !== 0) {
              searchKBArticles[e.value].map((item) => {
                  return lobOptionList.current.push({ value: item, label: item });
                  });
          }
          else {
            lobOptionList.current.push({ value: '', label: 'Not Applicable' });
            setLob({ value: '', label: 'Not Applicable' });
          }
        },
        [setRegion, setLob]
      );

      // Handle change LOB dropdown option
      const handleChangeLOB = useCallback(
        (e) => {
            setLob(e);
        },
        [setLob]
      );

      // Handle submit button click
      const handleSubmitClick = useCallback(
        event => {
            event.preventDefault();
            const submitObject = {
                'region':region.value,
                'lob':lob.value
            };
            setIsSubmitted(true);
            sendPostBack(submitObject);    
            if(!content.isLACFilters)context.setCaseWebFormArticleModal(true);
        },
        [region,lob,setIsSubmitted,sendPostBack]); 

    return (
        <div className="kBForm kbFormCustomMargin">
                <div className="webformInputLabel">
                    <span className="webformFieldTitle kBFields">Geography:</span>
                </div>
                <div className="input-wrap">
                    <Select
                        className="input-wrap-text-plugin kBFieldSelect"
                        name="region"
                        isDisabled={globalDisabled}
                        id={content.confirmLineItemsButtonId?content.confirmLineItemsButtonId+"_region":"region"}
                        options={regionOptionList.current}
                        placeholder="Select geography"
                        value={region}
                        onChange={(e) => handleChangeRegion(e)}
                        isSearchable={true}
                    />
                </div>
              
                <div className="webformInputLabel">
                    <span className="webformFieldTitle kBFields">Line Of Business:</span>
                </div>
                <div className="input-wrap">
                    <Select
                        className="input-wrap-text-plugin kBFieldSelect"
                        name="lob"
                        isDisabled={globalDisabled}
                        id={content.confirmLineItemsButtonId?content.confirmLineItemsButtonId+"_lob":"lob"}
                        options={lobOptionList.current}
                        placeholder="Please select geography first"
                        value={lob}
                        onChange={(e) => handleChangeLOB(e)}
                        isSearchable={true}
                    />
                </div>
                
                <div className="kBFormSubmitDiv">
                    <button
                       onClick={handleSubmitClick}
                        id={content.confirmLineItemsButtonId?content.confirmLineItemsButtonId:"defaultId"}
                        className="webformButton primary kBFormSubmit"
                        disabled={!region || !lob || isSubmitted || globalDisabled}>
                        Confirm</button>
                </div>
                <br/>
        </div>
    );
};
export default SearchKBFiltersADM;