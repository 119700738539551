import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React, { useEffect, useCallback, useContext } from 'react';
import Modal, { contextType } from 'react-modal';
import parse from 'html-react-parser';
import close from '../images/svgLatest/Close.svg';
import { AppContext } from "../util/appContext";
var classNames = require('classnames');


const TridiumArticleModal = ({ content }) => {
    const context = useContext(AppContext);

    var closeButton = classNames({
        'btn-primary': true,
        'article-popup-close': true,
    })
    var veiwArticle = classNames({
        'btn-primary': true,
        'viewFullArticle': true
    })

    window.onmessage = (e) => {
        switch (e.data.key) {
            case 'display_article_popup':
                document.getElementById('root').classList.remove('root-min');
                break;
            case 'hide_article_popup':
                document.getElementById('root').classList.add('root-min');
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const timeout = window.setTimeout(() => {
            if (context.articlePopUp == true) {
                context.setArticleModal(true);
            }
        }, 800);
        return () => { window.clearTimeout(timeout) };
    }, [])

    const handleArticlePopUpClose = useCallback(() => event => {
        context.setMenuOpen(false);
        //window.parent.postMessage({ key: 'hide_article_popup' }, '*');
        context.setArticlePopUp(false);
        context.setArticleModal(false);

    }, []);


    return (

        <div>
            {context.showArticleModal ?
                <Modal
                    isOpen={true}
                    ariaHideApp={false}
                    id="article"
                >
                    <div className="pop-up-close-container">
                        <img alt="Close" onClick={handleArticlePopUpClose()} style={{ cursor: 'pointer' }} src={close} />
                    </div>
                    <div className="article-popup-container">
                        <div>
                            <h1>{content.title}</h1>
                        </div>
                        {content.body ? <div>
                            {parse(content.body)}
                        </div> : null}

                    </div>
                    {content.communityURL ? <div style={{ margin: "50px 20px" }}>
                        {content.communityURL ? <a href={content.communityURL} className={veiwArticle} rel="noopener noreferrer" target="_blank" style={{ padding: "15px" }}>
                            {window.config.sbg === "PMT" && window.config.gbe === "UOP" && content.isServiceRequestButton ? 'View full Service Request' : 'VIEW FULL ARTICLE'}

                        </a> : ""}
                    </div> : null
                    }
                    {content.Url ? <div style={{ margin: "50px 20px" }}>
                        {content.Url ? <a href={content.Url} className={veiwArticle} rel="noopener noreferrer" target="_blank" style={{ padding: "15px" }}>
                            {window.config.sbg === "PMT" && window.config.gbe === "UOP" && content.isServiceRequestButton ? 'View full Service Request' : 'VIEW FULL ARTICLE'}
                        </a> : ""}
                    </div> : null
                    }

                </Modal>
                : null}
        </div>

    );
}

export default TridiumArticleModal;