import "react-app-polyfill/ie11";
import "core-js/stable";
import React from "react";
import { Components } from "botframework-webchat-component";
import styleSet from "../util/chatBotCustomStyleSet";
import PlainWebChat from "./PlainWebChat";
import Spinner from "./Spinner";
import  SatmetrixSurvey from "./SatmetrixSurvey";
import SurveyCard from "./SurveyCard";
import PrivacyPolicy from "./PrivacyPolicy";
import { getCookie } from '../util/cookieActions';
import LoadingErrorScreen from "./LoadingErrorScreen";
export default ({
  isMaximised,
  isSurveyOpen,
  store,
  directLine,
  showSpinner,
  isLiveAgent,
  handleMaximizeChange,
  handleCloseModalPopup,
  handleSurveyCardChange,
  appInsights,
  handleModalChange,
  showTimerBox,
  setShowTimerBox,
  handleSpinnerChange,
  userLanguage,
  showSurvey,
  setHeaderIconsDisplay,
  surveyLink,
  setSatmatrixSurvey,
  setSatmatrixSurveyIFrame,
  satmatrixSurveyIFrame,
  isAgentInitiated,
  setLiveAgent,
  setDirectLineTokenCall
}) => {
  return (
    <React.Fragment>
      {!!directLine ? (
        // We are using the "Composer" component here, which all descendants will have access to the Web Chat API by HOC-ing thru "connectToWebChat".
        <Components.Composer
          directLine={directLine}
          store={store}
          styleSet={styleSet({ isLiveAgent })}
        >
          {!showSpinner && !isSurveyOpen && !satmatrixSurveyIFrame ? (
            <PlainWebChat
              isMaximised={isMaximised}
              isLiveAgent={isLiveAgent}
              handleModalChange={handleModalChange}
              handleMaximizeChange = {handleMaximizeChange}
              handleCloseModalPopup = {handleCloseModalPopup}
              appInsights={appInsights}
              showTimerBox={showTimerBox}
              setShowTimerBox={setShowTimerBox}
              userLanguage={userLanguage}
              handleSurveyCardChange={handleSurveyCardChange}
              setHeaderIconsDisplay={setHeaderIconsDisplay}
              setLiveAgent={setLiveAgent}
            />
          ) : !isSurveyOpen && !satmatrixSurveyIFrame?  (
            <Spinner
              isMaximised={isMaximised}
              showSpinner={(isMaximised = { isMaximised })}
              handleModalChange={handleModalChange}
            />
          ) : 
            isSurveyOpen &&
            isMaximised && !satmatrixSurveyIFrame ?(
              <SurveyCard
                isMaximised={isMaximised}
                handleMaximizeChange={handleMaximizeChange}
                handleSurveyCardChange={handleSurveyCardChange}
                handleSpinnerChange={handleSpinnerChange}
                userLanguage={userLanguage}
                handleModalChange={handleModalChange}
                showSurvey={showSurvey}
                setHeaderIconsDisplay={setHeaderIconsDisplay}
                surveyLink={surveyLink}
                setSatmatrixSurvey={setSatmatrixSurvey}
                setSatmatrixSurveyIFrame={setSatmatrixSurveyIFrame}
                isAgentInitiated={isAgentInitiated}
              />
            )   : satmatrixSurveyIFrame && surveyLink != ""?<SatmetrixSurvey 
             surveyLink={surveyLink}
             />: 
           <Spinner
              isMaximised={true}
              showSpinner={true}
              handleModalChange={handleModalChange}
           />
            
          }
        </Components.Composer>
      ) : (
        isMaximised && !getCookie(window.config.businessUnit + '-isPrivacyAccepted') && window.config.isNewPrivacy==true ? window.config.showLoadingError ? <LoadingErrorScreen handleModalChange={handleModalChange}
        handleMaximizeChange = {handleMaximizeChange}
        handleCloseModalPopup = {handleCloseModalPopup}  isMaximised={isMaximised} marginTopSet={false}></LoadingErrorScreen>:<PrivacyPolicy  handleModalChange={handleModalChange}
        handleMaximizeChange = {handleMaximizeChange}
        handleCloseModalPopup = {handleCloseModalPopup}  isMaximised={isMaximised}  setDirectLineTokenCall={ setDirectLineTokenCall}></PrivacyPolicy>:<Spinner
          isMaximised={isMaximised}
          showSpinner={(isMaximised = { isMaximised })}
          handleModalChange={handleModalChange}
        />
      )}
    </React.Fragment>
  );
};
