import "react-app-polyfill/ie11";
import "core-js/stable";
import React, { useState, useCallback, useContext } from "react";
import { hooks } from "botframework-webchat-component";
import { AppContext } from "../util/appContext";
import StaticLabels from "../util/staticLabels.json";
import { getCookie} from '../util/cookieActions';

const classNames = require("classnames");
const { useSendPostBack, useSendMessage, useSendEvent } = hooks;

const CreateLead = ({ content, store }) => {
  var orderDetailBodytClass = classNames({
    orderDetailBody: true
  });
  const [customerType, setCustomerType] = useState('');
  const [industry, setIndustry] = useState('');
  const [responsibility, setResponsibility] = useState('');
  const [assistYou, setAssistYou] = useState('');
  const [comments, setComments] = useState('');
  const [optIn, setOptIn] = useState(content[0].inputs[5].value);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const context = useContext(AppContext);
  const sendPostBack = useSendPostBack();
  const sendMessage = useSendMessage();
  const sendEvent = useSendEvent();

  const onClick = useCallback(
    value => event => {
      event.preventDefault();
      if (event.target.id === 'submit' && !isDisabled) {
        const response = {
          customerType,
          industry,
          responsibility,
          assistYou,
          comments,
          optIn,
        }
        sendPostBack(JSON.stringify(response));
        setIsSubmitted(true);
      } else if (event.target.id === 'cancel') {
        setIsCancelled(true);
        sendPostBack(`cancelBusinessExpertFlow`);
      }
      context.setMenuOpen(false);
      if(getCookie(window.config.businessUnit + '-EnableSendBox')) {
        sendEvent('enableSendBoxEventReceived');
      }
      context.setDisableSendBox(true);
    }, [
      sendPostBack,
      customerType,
      industry,
      responsibility,
      assistYou,
      comments,
      optIn,
      isDisabled,
      setIsSubmitted,
      setIsCancelled,
    ]
  );
  const onChange = useCallback(
    (value) => event => {
      let customerTypeV,
          industryV,
          responsibilityV,
          assistYouV,
          optInV;
      switch (event.target.id) {
        case 'customer_type':
          setCustomerType(event.target.value);
          customerTypeV = event.target.value;
          break;
        case 'industry':
          setIndustry(event.target.value);
          industryV = event.target.value;
          break;
        case 'responsibility':
          setResponsibility(event.target.value);
          responsibilityV = event.target.value;
          break;
        case 'assist_you':
          setAssistYou(event.target.value);
          assistYouV = event.target.value;
          break;
        case 'comments':
          setComments(event.target.value);
          break;
        case 'opt_in':
          setOptIn(event.target.checked);
          optInV = event.target.checked;
          break;
      }

      if (typeof customerTypeV == 'undefined') {
        customerTypeV = customerType;
      }

      if (typeof industryV == 'undefined') {
        industryV = industry;
      }

      if (typeof responsibilityV == 'undefined') {
        responsibilityV = responsibility;
      }

      if (typeof assistYouV == 'undefined') {
        assistYouV = assistYou;
      }

      if (typeof optInV == 'undefined') {
        optInV = optIn;
      }

      const isDisabled = !(customerTypeV && industryV && responsibilityV && assistYouV && optInV);
      setIsDisabled(isDisabled);
      context.setMenuOpen(false);
      if(getCookie(window.config.businessUnit + '-EnableSendBox')) {
        sendEvent('enableSendBoxEventReceived');
      }
      context.setDisableSendBox(true);
    }, [
      setCustomerType,
      setIndustry,
      setResponsibility,
      setAssistYou,
      setComments,
      setOptIn,
      setIsDisabled,
      customerType,
      industry,
      responsibility,
      assistYou,
      optIn,
    ]
  );

  const rows = [];
  for (let input of content[0].inputs) {
    let element;
    switch (input.type) {
      case 'text':
        element = <input className="createLead" placeholder={input.placeholder} onBlur={onChange(input)} type={input.type} id={input.id} name={input.name} value={input.value} />;
        break;
      case 'checkbox':
        element = <input  placeholder={input.placeholder} onChange={onChange(input)} type={input.type} id={input.id} name={input.name} defaultChecked={optIn} />;
        break;
      case 'textarea':
        element = <textarea  className="createLead" placeholder={input.placeholder} onBlur={onChange(input)} type={input.type} id={input.id} name={input.name}>{input.value}</textarea>;
        break;    
      case 'select':
        const options = [];
        for (let option of input.options) {
          options.push(<option className="createLeadOptions" value={option.value}>{option.display}</option>);
        }
        element = <select className="createLead" id={input.id} onChange={onChange(input)} placeholder={input.placeholder}>
          {options}
        </select>;
        break;
    }
    rows.push(<p>
      {input.id == 'opt_in' ? element : ''}
      <label for={input.for} className="createLeadLabel">
        <span>{input.label}  { ['comments', 'opt_in'].includes(input.id) ? '' : <span style={{color:'#F15A4F'}}>*</span>}
        {input.id == 'opt_in' && <span className="OptInfoTooltip" title={StaticLabels["en"].TooltipInfo}> &#9432;</span> }
        </span>
      </label>{input.id == 'opt_in' ? '' : <br/>}
      {input.id == 'opt_in' ? '' : element}
      <br /><br />
    </p>);
  }
  return (
    <div>
      <ul className="suggestedAction">
        <div class="botTextBox conversationTextBox">
          <div class="conv-text">
        {rows}
            <div className="suggestedAction">
            <button id="cancel" className={!isSubmitted && !isCancelled ? 'suggesteActionButton' : 'hide'} type="button" onClick={onClick()}>Cancel</button>
              <button style={{backgroundColor : isDisabled ? '#ddd' :'#007BC2',color:'#fff' }} id="submit" disabled={isDisabled} className={!isSubmitted && !isCancelled  ? 'suggesteActionButton' : 'hide'} type="button" onClick={onClick()}>Submit</button>
            </div>
          </div>
        </div>
      </ul>
    </div>
  );
};

export default CreateLead;
