import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React from 'react';
import HoneywellLogo from '../images/svgLatest/footerHoneywellLogo.svg';
import footerTridiumLogo from '../images/svgLatest/footerTridiumLogo.svg';
var classNames = require('classnames');


const Footer = ({isMaximised}) => {

  var footerClass = classNames({
    footer: true,
    displayNone: !isMaximised,    
  });
    return (
        <div className={footerClass}>
        { window.config.businessUnit === "HCE-TRIDIUM" ? <img alt="Honeywell" className="footerLogo" src={footerTridiumLogo} /> :
        <img alt="Honeywell" className="footerLogo" src={HoneywellLogo} />}
      </div>
    );
}


export default Footer;