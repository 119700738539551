import React from 'react';

const DownloadCard = ({content}) => {
    return(
        <div className="botActivityClass activityClass" style={{marginLeft:"3rem"}}>
            <div className="botTextBox conversationTextBox workflowButtonTextBox">
                <div className="conv-text">
                    <div className="documentDownload">
                        <span>
                            <div style={{display:"flex"}}>
                                <div style={{display:"block",marginRight:"5px",fontSize:"15px"}}>
                                    <a href={content.attachmentLink} target="_blank">
                                        {content.name}
                                    </a>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadCard;