import "react-app-polyfill/ie11";
import "core-js/stable";
import DEFAULT_OPTIONS from "./defaultStyleOptions";
import { createStyleSet } from "botframework-webchat";
import userImage from "../images/Default_User_Icon.png";

const customStyleSet = createStyleSet(DEFAULT_OPTIONS);

customStyleSet.suggestedActions = Object.assign(
  {},
  customStyleSet.suggestedActions,
  {
    "&": {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center"
    }
  }
);

customStyleSet.suggestedAction["& > button"] = Object.assign(
  {},
  customStyleSet.suggestedAction["& > button"],
  {
    cursor: "pointer"
  }
);

const styleSet = styles => {
  // if (styles.isLiveAgent) {
  //   customStyleSet.options.botAvatarImage = userImage;
  // }
  return customStyleSet;
};

export default styleSet;
