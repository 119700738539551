import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React from 'react';
import ImBackButton from './ImBackButton';
import MessageBackButton from './MessageBackButton';
import PostBackButton from './PostBackButton';
import add from "../images/svgLatest/add.svg";
require('es6-promise').polyfill();
require('isomorphic-fetch');

// "cardAction" could be either, "imBack", "messageBack", or "postBack".
export default ({ cardAction, handleFocusSendBox, handleClick }) => {
  switch (cardAction.type) {
    case 'messageBack':
      return <MessageBackButton handleFocusSendBox={handleFocusSendBox} cardAction={cardAction} />;

    case 'postBack':
      return <PostBackButton handleFocusSendBox={handleFocusSendBox} cardAction={cardAction} />;

    case 'moreActions':
      return (
        <button  onClick={handleClick}
                 className="suggesteActionButton"  
                 type="button">
                 {cardAction.title}
                 <img alt="add" className="addIcon" src={add} />
        </button>
      )

    // Suggested Actions as URL
    case 'ActionTypes.OpenUrl':
      return (
        <a
          href={cardAction.value}
          style={{ paddingTop: "10px" }}
          rel="noopener noreferrer"
          target="_blank"
        >
            <button className="suggesteActionButton"  type="button">
         {cardAction.title}</button>
        </a>
      );

    default:
      return <ImBackButton handleFocusSendBox={handleFocusSendBox} cardAction={cardAction} />;
  }
};
