import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React, { useContext } from 'react';
import { AppContext } from "../util/appContext";
import ErrorHandler from "../components/ErrorHandler"


const SatmetrixSurvey = ({ surveyLink }) => {
  const context = useContext(AppContext);
  context.setShowHeaderFooter(true);
  context.setHideStickyMenu(true);

  return (
    <div className="webChat customscrollbar">
      <div className="holds-the-iframe">
        {surveyLink === "error" ?
         <ErrorHandler
         isMaximised={true}
         message={{
           text: "The service is currently unavailable"
         }}
       />
          :
          <iframe className="setMatrixSurvey" src={surveyLink}></iframe>
          }
      </div>
    </div>
  );


}

export default SatmetrixSurvey;
