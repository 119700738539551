import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React, {useState, useCallback} from 'react';
var classNames = require('classnames');

const Rating = ({handleFeedBackValueChange}) => {

    const ratingObject = [
       {
            verySad:true,
            presseVerySad:false,
        },
        {
            sad:true,
            pressedSad:false,
        },
       {
            nuetral:true,
            pressedNuetral:false,
        },
       {
            happy:true,
            pressedHappy:false,
        },
       {
            veryHappy:true,
            pressedVeryHappy:false,
        }
    ]

    const ratingClass = ratingObject.map(ele => classNames(ele));
    const [rating , setRating] =  useState({ratingObject:ratingObject, ratingClass:ratingClass});
  
const handleChange = useCallback( value => event => {
  
    const ratingObject = rating.ratingObject.map( ele => {return {...ele}});
  Object.keys(ratingObject[value]).forEach((ele, index) => {
        ratingObject[value][ele] = !ratingObject[value][ele];
    });

     ratingObject.forEach((ele,index1) =>{
        if(index1 !== value){
            Object.keys(ratingObject[index1]).forEach((ele, index) => {
                if(index === 0){
                  ratingObject[index1][ele] = true;
                }else{
                  ratingObject[index1][ele] = false;
                }
              });
        }
    });

     const ratingClass = ratingObject.map(ele => classNames(ele));
     handleFeedBackValueChange(value,ratingObject);
    setRating({ratingObject:ratingObject,
              ratingClass:ratingClass});
   
},[rating,handleFeedBackValueChange], setRating)

    return(
        <div>
            <ul className="ratingContainer">
                <li title="Very Sad">
                    <button onClick={handleChange(0)} className={rating.ratingClass[0]}>

                    </button>
                </li>
                <li  title="Sad">
                    <button onClick={handleChange(1)}  className={rating.ratingClass[1]}>
                        
                    </button>
                </li>
                <li  title="Nuetral">
                    <button onClick={handleChange(2)}  className={rating.ratingClass[2]}>
                        
                    </button>
                </li>
                <li  title="Happy">
                    <button onClick={handleChange(3)}  className={rating.ratingClass[3]}>
                        
                    </button>
                </li>
                <li  title="Very Happy">
                    <button onClick={handleChange(4)}  className={rating.ratingClass[4]}>
                        
                    </button>
                </li>
            </ul>
        </div>
    );
}

export default Rating;
