import "react-app-polyfill/ie11";
import "core-js/stable";
import React, { useCallback, useContext } from "react";
import { hooks } from "botframework-webchat-component";
import { AppContext } from "../util/appContext";
import { getCookie } from '../util/cookieActions';

const { useSendMessage, useSendEvent } = hooks;
var classNames = require("classnames");
const DropDownCard = ({ content }) => {
  const context = useContext(AppContext);
  const sendMessage = useSendMessage();
  const sendEvent = useSendEvent();
  const onChange = useCallback(
      value => event => {
          event.preventDefault();
          if(value.onSelect == true && event.target.value) {
              context.setMenuOpen(false);
              if(getCookie(window.config.businessUnit + '-EnableSendBox')) {
                sendEvent('enableSendBoxEventReceived');
              }
              context.setDisableSendBox(true);
              sendMessage(`${event.target.value}`); 
          }
      }
  );

  const rows = [];
  var Icon = classNames({
    Icon: true,
    IconUser: true
  });

  for (let input of content.inputs) {
    let element;
    switch (input.type) {
      case 'select':
        const options = [];
        for (let option of input.options) {
          options.push(<option className="createLeadOptions" value={option.value}>{option.display}</option>);
        }
        element = <select className="createLead" onChange={onChange(input)} placeholder={input.placeholder}>
          {options}
        </select>;
        break;
    }
    rows.push(<p>
      <label for={input.for} className="createLeadLabel">
        <span>{input.label}</span>
      </label><br />
      {element}
      <br />
    </p>);
  }
  return (
    <div style={{ display: 'flex' }}>
      {!window.config.isNewUI && <div className={Icon}></div>}
      <ul className="suggestedAction">
        <div class="botTextBox conversationTextBox">
          <div class="conv-text">
            {rows}
          </div>
        </div>
      </ul>
    </div>
  );
};

export default DropDownCard;