import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React from 'react';
import Markdown from "markdown-to-jsx";
import Close from "../images/svgLatest/Close_blue_withoutCircle.svg";

const MyLink = ({ children, ...props }) => {
  return (
    <a style={{ fontSize: "14px" , textDecoration:'underline'}} {...props}>
      {children}
    </a>
  );
};

const AccessDenied = ({ content, handleModalChange}) => {
 
  return (
    <div>
      <div className="closeBotDiv closeBotDivMargin">
        <img alt="Close" className="closeBotIcon" title="Close Bot" src={Close} onClick={handleModalChange({
          isOpenModal: false,
          value: null,
          target: "closeLaunchIcon"
        })}></img>
      </div>
    <div className="accessDeny">
      {!window.config.isNewUI ? 
      <div className="Icon accessDenyBotImg"></div>
      :
      <div className="accessDenyBotImg"></div>
    }
    
    <div className="botTextBox accessDenyText">
      <Markdown
              options={{
                overrides: {
                  forceBlock: true,
                  forceInline: false,
                  a: {
                    component: MyLink,
                    target: "_blank",
                    props: {
                      className: "markdown-link",
                      target: "_blank"
                    }
                  }
                }
              }}
            >{content.text}</Markdown>
    </div>
    </div>
    </div>
  )
}

export default AccessDenied;