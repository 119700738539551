import "react-app-polyfill/ie11";
import "core-js/stable";
import React, { useState, useCallback, useEffect, useRef,useContext } from "react";
import { hooks } from "botframework-webchat-component";
import searchKBArticles from '../util/searchKBArticlesAMRC.json';
import Select from "react-select";
import StaticLabels from "../util/staticLabels.json";
import { AppContext } from "../util/appContext";
import { getCookie } from "../util/cookieActions";

const { useSendPostBack } = hooks;

const SearchKBFiltersAMRC = ({ content, store, userLanguage }) => {
    const context = useContext(AppContext);
    const [region, setRegion] = useState(content && content.region ? { value: content.region, label: content.region } : null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const sendPostBack = useSendPostBack();
    const regionOptionList = useRef([]);
    const [globalDisabled,setGlobalDisabled] = useState(false)
     
    if(searchKBArticles) {
        regionOptionList.current = [];
        Object.keys(searchKBArticles).forEach((item) => {
            regionOptionList.current.push({ value: item, label: item });
        });
    }
    useEffect(() => {
        let checkHistory = getCookie(window.config.businessUnit + '-CheckOrderLob');
        if(checkHistory){
            checkHistory=JSON.parse(checkHistory); 
        let isExisting = Array.isArray(checkHistory) && checkHistory?.map(i => i?.id).includes(content?.confirmLineItemsButtonId) 
        if (isExisting) {
            let found = checkHistory?.find(i => i?.id === content?.confirmLineItemsButtonId)
            setGlobalDisabled(true);
            if(found && found?.region) setRegion({ value: found?.region, label: found?.region });
        }
        }
    },[]);

    // Handle change region dropdown option
    const handleChangeRegion = useCallback(
        (e) => {
            setRegion(e);
           },
        [setRegion]
      );

      // Handle submit button click
      const handleSubmitClick = useCallback(
        event => {
            event.preventDefault();
            const submitObject = {
                'region':region.value
            };
            setIsSubmitted(true);
            sendPostBack(submitObject);
            if(!content.isLACFilters)context.setCaseWebFormArticleModal(true);
        },
        [region,setIsSubmitted,sendPostBack]); 

    return (
        <div className="kBForm kbFormCustomMargin">
                <div className="webformInputLabel">
                    <span className="webformFieldTitle kBFields">
                    {userLanguage ? StaticLabels[userLanguage.toLowerCase()].Region
                    : "Geography:"}
                    </span>  
                </div>
                <div className="input-wrap">
                    <Select
                        className="input-wrap-text-plugin kBFieldSelect"
                        name="region"
                        isDisabled={globalDisabled}
                        id={content.confirmLineItemsButtonId?content.confirmLineItemsButtonId+"_region":"region"}
                        options={regionOptionList.current}
                        placeholder=  {userLanguage ? StaticLabels[userLanguage.toLowerCase()].SelectRegion : "Select geography"}
                        value={region}
                        onChange={(e) => handleChangeRegion(e)}
                        isSearchable={true}
                    />
                </div>
              
                <div className="kBFormSubmitDiv">
                    <button onClick={handleSubmitClick}
                      id={content.confirmLineItemsButtonId?content.confirmLineItemsButtonId:"defaultId"}
                        className="webformButton primary kBFormSubmit"
                        disabled={!region || isSubmitted || globalDisabled}>
                         {userLanguage ? StaticLabels[userLanguage.toLowerCase()].Confirm
                        : "Confirm"}
                        </button>
                </div>
                <br/>
        </div>
    );
};
export default SearchKBFiltersAMRC;