import "react-app-polyfill/ie11";
import "core-js/stable";
import { hooks } from "botframework-webchat-component";
import React, { useCallback, useState, useContext, useEffect } from "react";
import Attachment from "./Attachment";
import SuggestedActions from "./SuggestedActions";
import getValueOrUndefined from "../util/getValueOrUndefined";
import MenuIcon from "../images/svgLatest/MenuIcon_HBT.svg";
import { debounce } from "throttle-debounce";
import useKeyPress from "./useKeyPress";
import Markdown from "markdown-to-jsx";
import { getSuggestedInput } from "../util/httprequest";
import "../css/three-dots.css";
import DOMPurify from "dompurify";
import setLastMessage from "../util/setLastMessage";
import { AppContext } from "../../src/util/appContext";
import TimerBox_Main from "./TimerBox_Main";
import TimerBoxHBT from "./TimerBox_HBT";
import StaticLabels from "../util/staticLabels.json";
import {  getCookie } from '../util/cookieActions';
import Restart_HBT from '../images/svgLatest/Restart_HBT.svg';
import LiveAgent_HBT from '../images/svgLatest/LiveAgent_HBT.svg';
import CloseMenu_HBT from '../images/svgLatest/CloseMenu_HBT.svg';
var classNames = require("classnames");

function scrollDown(bodyRef) {
  let a = document.getElementById("web-chat")
    ? (document.getElementById("web-chat").scrollTop = document.getElementById(
      "web-chat"
    ).scrollHeight)
    : "";
  a = bodyRef.current
    ? (bodyRef.current.scrollTop = bodyRef.current.scrollHeight)
    : "";
  a = "";
  return a;
}

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const { useActivities, useSendMessage, useActiveTyping, useSendMessageBack, useSendEvent } = hooks;

const PlainWebChat = ({
  isMaximised,
  isLiveAgent,
  handleModalChange,
  handleMaximizeChange,
  handleCloseModalPopup,
  appInsights,
  showSendBox,
  showTimerBox,
  setShowTimerBox,
  userLanguage,
  handleSurveyCardChange,
  setHeaderIconsDisplay,
  setLiveAgent
}) => {
  const [activities] = useActivities();
  const [typingIndicator] = useActiveTyping(5000);
  const sendMessage = useSendMessage();
  const sendEvent = useSendEvent();
  const sendMessageBack = useSendMessageBack();
  const textInput = React.useRef(null);

  const [sendBoxValue, setSendBoxValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  // state to store customer typing event
  const [typingTimeout, setTypingTimeout] = useState(null)

  const context = useContext(AppContext);

  useKeyPress(setSearchResult);
  var botAvatar = classNames({
    botAvatarClass: true
  });
  var liveAgentIcon = classNames({
    liveAgentIcon: true
  });
  var Icon = classNames({
    Icon: true
  });
  const bodyRef = React.useRef();
  scrollDown(bodyRef);
  const debounceFun = debounce(200, async (searchValue, debounceFun) => {
    let suggestedInput = [];
    if (searchValue && window.config.gbe !== 'PSS' && window.config.gbe !== 'HPS') {
      suggestedInput = await getSuggestedInput(searchValue, appInsights);
    } else {
      setTimeout(() => {
        setSearchResult([]);
      }, 2000);
    }
    if (suggestedInput) {
      let suggestedInputResults = [];
      suggestedInput.map(item =>
        suggestedInputResults.push({
          "intent": item["intent"], "suggestedText": item["@search.text"].replace(
            new RegExp(searchValue, "ig"),
            `**${searchValue}**`
          )
        })
      );
      setSearchResult(suggestedInputResults);
    } else {
      setSearchResult([]);
    }
  });

  const handleDebounce = React.useRef(debounceFun);

  const handleChange = useCallback(
    ({ target: { value } }) => {

      // disabling typing indicator for any accidental issues.
      if (window.config.businessUnit === "HBT-GCEGBT" && context.isLiveAgent) {
        window.config.showTypingIndicator = false;
      }

      if (!value) {
        setTimeout(() => handleDebounce.current(value, debounceFun), 300);
      }

      setSendBoxValue(value);
      handleDebounce.current(DOMPurify.sanitize(value), debounceFun);

      // clearing any previous typing event
      if (typingTimeout) {
        clearTimeout(typingTimeout)
      }
      // creating typing event after detecting typing event for 6s duration
      setTypingTimeout(
        setTimeout(() => {
          // sending event only when agent is live.
          if (window.config.businessUnit === "HBT-GCEGBT" && context.isLiveAgent) {
            window.config.showTypingIndicator = false;
            sendEvent("customerTypingIndicationToAgent", { isCustomerTyping: false });
          }

        }, 2500)
      )
     
    },
    [sendBoxValue, setSendBoxValue]
  );

  // useEffect to keep on checking if user is typing or not.
  useEffect(() => {
    if (sendBoxValue) {
      // detecting customer is typing so disabling typing indicator if any
      // creating typing true event
      var timer = setTimeout(() => {
        if (window.config.businessUnit === "HBT-GCEGBT" && context.isLiveAgent) {
          sendEvent("customerTypingIndicationToAgent", { isCustomerTyping: true })
        }
      }, 500)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [sendBoxValue])

  const handleFocusSendBox = useCallback(() => {
    if (window.config.businessUnit !== "HCE-MARKETING") {
      textInput.current.focus();
    }
  }, [textInput]);

  const handleSubmit = useCallback(
    event => {
      // disabling typing indicator for any accidental issues.
      if (window.config.businessUnit === "HBT-GCEGBT" && context.isLiveAgent) {
        window.config.showTypingIndicator = false;

        setTimeout(() => {
          window.config.showTypingIndicator = true;
        }, 5000)
      }
      let value = event ? event.preventDefault() : "";
      value = "";
      context.setMenuOpen(false);
      context.setMoreOptionsClick(false);
      if (context.isLiveAgent) {
        context.setDisableSendBox(false);
      }
      else {
        if (getCookie(window.config.businessUnit + '-EnableSendBox')) {
          sendEvent('enableSendBoxEventReceived');
        }
        context.setDisableSendBox(true);
      }
      setLastMessage(DOMPurify.sanitize(sendBoxValue));
      scrollDown(bodyRef);
      const messageText = sendBoxValue.trim();
      sendMessage(DOMPurify.sanitize(messageText));
      setSendBoxValue(value);
      setSearchResult([]);
      setTimeout(() => handleDebounce.current(value, debounceFun), 300);
      // sendMessage("");
    },
    [sendBoxValue, sendMessage, setSendBoxValue, setSearchResult]
  );

  const handleSuggestedInputSelection = useCallback(
    data => event => {
      textInput.current.focus();
      context.setMoreOptionsClick(false);
      setSearchResult([]);
      const messageText = data.suggestedText.replace(/\**/gi, "");
      if (data.intent) {
        sendMessageBack(`suggestedInput-${data.intent}`, messageText, messageText);
      } else {
        sendMessage(messageText);
      }
      handleDebounce.current("");
      sendMessage("");
      setSendBoxValue("");
      if (context.isLiveAgent) {
        context.setDisableSendBox(false);
      }
      else {
        if (getCookie(window.config.businessUnit + '-EnableSendBox')) {
          sendEvent('enableSendBoxEventReceived');
        }
        context.setDisableSendBox(true);
      }
    },
    [sendMessage]
  );

  var webchatClass = classNames({
    webChat: true,
    customscrollbar: true,
    displayNone: !isMaximised,
    webChatScroll: context.enableScroll
  });

  var LiveAgentImgPositionClass = classNames({
    LiveAgentImgPosition: true,
    displayNone: !isMaximised
  });

  var sendBoxContainerClass = classNames({
    "send-box-container": true,
    displayNone: !isMaximised,
    sendBox_HBT: window.config.isNewUI ? true : false
  });

  var sendBoxMenuIcon = classNames({
    sendBoxMenuIcon: true,
    displayNone: !isMaximised
  });

  var suggestedInputClass = classNames({
    suggestedInputContainer: true,
    customscrollbar: true,
    displayNone: !isMaximised
  });

  var sendBoxDisableClass = classNames({
    'send-box-input': true,
    disableSendBox: true
  });

  var hbtMargin = classNames({
    hbtMargin: window.config.sbg === "HBT"
  });

  const handleMenuClick = () => {
    context.setMenuOpen(!context.menuOpen);
  };

  const closeMenuClick = () => {
    context.setMenuOpen(false);
  };

  const menuOptionsClick = () => {
    context.setMenuOpen(false);
    sendMessage("Menu");
  };

  const liveAgentOptionClick = () => {
    context.setMenuOpen(false);
    const message=window.config.language ? StaticLabels[window.config.language].LiveAgent :"live agent";
    sendMessage(message);
  }

  return (
    <React.Fragment>
      <div ref={bodyRef} className={webchatClass} id="web-chat">
        <ul className="welcomecardBotDivHeight">
          {activities
            // Currently, this sample only displays an activity of type "message"
            .filter(({ type }) => type === "message")
            // We need to hide "postBack" message sent by the user
            .filter(
              ({ channelData: { postBack } = {}, from: { role } }) =>
                !(role === "user" && postBack)
            )
            // Normalize the activity:
            // - Every activity should have an "attachments" array, consisting of zero or more attachments:
            // - If this is a "messageBack" message, we should use the "displayText",
            //   because "text" is being submitted to bot, and "displayText" is what we use to override what the bot displays to the user.
            .map(activity => ({
              ...activity,
              attachments: activity.attachments || [],
              text:
                getValueOrUndefined(
                  activity,
                  "channelData",
                  "messageBack",
                  "displayText"
                ) || activity.text
            }))
            // Filter out all empty messages (no attachments or text)
            .filter(({ attachments, text }) => attachments.length || text)
            .map((activity, index) => (
              <React.Fragment key={activity.id || index}>
                {scrollDown(bodyRef)}
                <li className="botDivHeight">
                  {!!activity.text && (
                    // We are using the very same component for text message and attachments.
                    // This is because, attachments can also have "text/markdown" or "text/plain" content.
                    // In this case, we prefer to have a single component for both of them.
                    <div>
                      <Attachment
                        content={activity.text}
                        contentType={
                          activity.textFormat === "markdown"
                            ? "text/markdown"
                            : "text/plain"
                        }
                        // from={
                        //   isLiveAgent === false
                        //     ? activity.from.role
                        //     : "agent"
                        // }
                        //from= {activity.from.role === "user" ? "user": (isLiveAgent === false ?"bot":"agent") }
                        from={activity.from.role}
                        timestamp={activity.timestamp}
                        suggestedActions={activity.suggestedActions}
                        activity={activity}
                        attachments={activity.attachments}
                        inputHint={activity.inputHint}
                        isLiveAgent={isLiveAgent}
                        setLiveAgent={setLiveAgent}
                        handleModalChange={handleModalChange}
                        userLanguage={userLanguage}
                      ></Attachment>
                    </div>
                  )}
                  {!!activity.attachments.length && (
                    <ul className="botDivHeight">
                      {activity.attachments.map((attachment, index) => (
                        <li className="botDivHeight" key={index}>
                          <Attachment
                            activity={activity}
                            userLanguage={userLanguage}
                            attachments={activity.attachments}
                            handleModalChange={handleModalChange}
                            handleMaximizeChange={handleMaximizeChange}
                            handleCloseModalPopup={handleCloseModalPopup}
                            timestamp={activity.timestamp}
                            {...attachment}
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              </React.Fragment>
            ))}
          <SuggestedActions handleFocusSendBox={handleFocusSendBox} userLanguage={userLanguage} />
          {!showTimerBox && context.enableScroll && !isEmpty(typingIndicator) && window.config.showTypingIndicator ? (
            <li>
              <div>
                <div>
                  <div className={"typingIndicator"}>
                    {context.isLiveAgent === false && !window.config.isNewUI &&
                      <div className={Icon}></div>}
                    {context.isLiveAgent && !window.config.isNewUI &&
                      <div className={liveAgentIcon}>
                        {context.liveAgentName.toUpperCase().substring(0, 1)}
                      </div>
                    }
                    {/* <div className={botAvatar}>
                      <Components.Avatar height={20} fromUser={false} />
                    </div> */}

                    <div className="dot">
                      <div className="dot-flashing"></div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ) : (
            ""
          )}
        </ul>
      </div>
      {scrollDown(bodyRef)}
      {/* {context.enableScroll && window.config.sbg === "PMT" && window.config.gbe === "AMRC" ? (
        <div className={LiveAgentImgPositionClass}>
          <iframe
            src="./LiveAgent.html"
            width="160px"
            height="80px"
            style={{ border: "none", float: "right" }}
          />
        </div>
      ) : (
        ""
      )} */}
      {showTimerBox && window.config.businessUnit === "HBT-GCEGBT" &&
        <TimerBoxHBT
          isMaximised={isMaximised}
          handleModalChange={handleModalChange}
          showTimerBox={showTimerBox}
          setShowTimerBox={setShowTimerBox}
          handleSurveyCardChange={handleSurveyCardChange}
          setHeaderIconsDisplay={setHeaderIconsDisplay}
          setLiveAgent={setLiveAgent}
        >
        </TimerBoxHBT>
      }
      {showTimerBox && window.config.businessUnit !== "HBT-GCEGBT" &&
        <TimerBox_Main
          isMaximised={isMaximised}
          handleModalChange={handleModalChange}
          showTimerBox={showTimerBox}
          setShowTimerBox={setShowTimerBox}
          handleSurveyCardChange={handleSurveyCardChange}
          setHeaderIconsDisplay={setHeaderIconsDisplay}
          setLiveAgent={setLiveAgent}
        >
        </TimerBox_Main>
      }
      {context.showSendBox && window.config.isNewUI &&
        <div className={sendBoxMenuIcon}>
          <div className="menuIcon">
            <img
              alt="MenuIcon"
              src={MenuIcon}
              onClick={handleMenuClick}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className={sendBoxContainerClass}>
            {(searchResult.length && (context.enableSendBoxHBT || !context.disableSendBox) && !context.isLiveAgent ? (
              <div className={suggestedInputClass}>
                <ul>
                  {searchResult.map((data, index) => (
                    <li key={index}>
                      <button
                        className="suggestedInputButton"
                        key={index}
                        onClick={handleSuggestedInputSelection(data)}
                      >
                        <p className="suggestedInputImage" />
                        <p className="suggestedInputData">
                          <Markdown>{data.suggestedText}</Markdown>
                        </p>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null)}
            <div className="send-box">
              {/* This is the send box, and suggested actions change based on the send box, not activity */}
              {/* <SendBox/> */}
              <form onSubmit={handleSubmit} className={hbtMargin}>

                {(context.enableSendBoxHBT || !context.disableSendBox?
                  <input
                    ref={textInput}
                    placeholder={
                      context.isLiveAgent === false
                        ? window.config.businessUnit === "HCE-TRIDIUM" ? (userLanguage ? StaticLabels[userLanguage.toLowerCase()].StartConversationTridium : "Write a message to Tridium Assist") :
                          (userLanguage ? StaticLabels[userLanguage.toLowerCase()].StartConversation : "Write a message to Honeywell Assist")
                        : context.liveAgentName ? "Write a message to " + context.liveAgentName : "Write a message to Live Agent"
                    }
                    className="send-box-input"
                    autoFocus={true}
                    onChange={handleChange}
                    type="textbox"
                    value={sendBoxValue}
                    maxLength={500}
                  /> :
                  <input
                    ref={textInput}
                    className={sendBoxDisableClass}
                    value=""
                    type="textbox"
                    disabled
                  />)}
              </form>
            </div>
            {(context.enableSendBoxHBT || !context.disableSendBox ? <button onClick={handleSubmit} className="send-box-button"></button> : null)}
          </div>
        </div>}
      {context.showSendBox && !window.config.isNewUI && (
        <div className={sendBoxContainerClass}>
          {(searchResult.length && !context.disableSendBox ? (
            <div className={suggestedInputClass}>
              <ul>
                {searchResult.map((data, index) => (
                  <li key={index}>
                    <button
                      className="suggestedInputButton"
                      key={index}
                      onClick={handleSuggestedInputSelection(data)}
                    >
                      <p className="suggestedInputImage" />
                      <p className="suggestedInputData">
                        <Markdown>{data.suggestedText}</Markdown>
                      </p>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ) : null)}

          <div className="send-box">
            {/* This is the send box, and suggested actions change based on the send box, not activity */}
            {/* <SendBox/> */}
            <form onSubmit={handleSubmit} className={hbtMargin}>

              {(!context.disableSendBox ?
                <input
                  ref={textInput}
                  placeholder={
                    context.isLiveAgent === false
                      ? window.config.businessUnit === "HCE-TRIDIUM" ? (userLanguage ? StaticLabels[userLanguage.toLowerCase()].StartConversationTridium : "Write a message to Tridium Assist") :
                        (userLanguage ? (context.restrictTextBox ? StaticLabels[userLanguage.toLowerCase()].StartConversationNumber : StaticLabels[userLanguage.toLowerCase()].StartConversation) : (context.restrictTextBox ? "Please enter only numbers" : "Write a message to Honeywell Assist"))
                      : context.liveAgentName ? "Write a message to " + context.liveAgentName : "Write a message to Live Agent"
                  }
                  className="send-box-input"
                  autoFocus={true}
                  onChange={handleChange}
                  type={window.config.businessUnit === "SPS-ECOMM" ? (context.restrictTextBox ? "number" : "textbox") : "textbox"}
                  value={sendBoxValue}
                  maxLength={500}
                /> :
                <input
                  ref={textInput}
                  className={sendBoxDisableClass}
                  value=""
                  type="textbox"
                  disabled
                />)}

            </form>
          </div>

          {(!context.disableSendBox ? <button onClick={handleSubmit} className="send-box-button"></button> : null)}

        </div>)
      }
      {
        (context.menuOpen && window.config.isNewUI) &&
        <div className='menuContainer_HBT'>
          {/* {!context.isLiveAgent && !context.hideStickyMenu && <p onClick={menuOptionsClick}><img alt="SupportOptions" src={SupportOptions_HBT} /> {liveAgentStaticLabels[window.config.language].SupportOptions} </p>} */}
          <p onClick={handleModalChange({
            isOpenModal: true,
            value: null,
            target: "refresh"
          })}><img alt="Restart" src={Restart_HBT} /> {window.config.businessUnit === 'HBT-GCEGBT' ? StaticLabels[window.config.language].RestartChat : 'Restart Chat'} </p>
          {window.config.businessUnit === 'HBT-GCEGBT' && !context.isLiveAgent && !showTimerBox && !context.hideStickyMenu && <p onClick={liveAgentOptionClick}><img alt="LiveAgent" src={LiveAgent_HBT} /> {
            window.config.businessUnit === 'HBT-GCEGBT' ?
              StaticLabels[window.config.language].LiveAgent : 'Live Agent'} </p>}
          <p onClick={closeMenuClick}> <img alt="Close" src={CloseMenu_HBT} /> {window.config.businessUnit === 'HBT-GCEGBT' ? StaticLabels[window.config.language].CloseMenu : 'Close Menu'} </p>
        </div>

      }

    </React.Fragment>
  );
};

export default PlainWebChat;
