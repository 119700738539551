import React, { useState } from "react";
import "react-app-polyfill/ie11";
import "core-js/stable";
import parse from 'html-react-parser';

const FaqItem=(content)=>{
    const [expandedItems, setExpandedItems] = useState({});
    const toggleAccordion = (index) => {
        setExpandedItems((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));

    };
    

    return (

        <div>

            {content.content.faq.map((faqItem, index) => (
                <div className="FAQindent botActivityClass activityClass" key={index}>

                    <div className="botTextBox conversationTextBox faqItemBox">

                        <div className={`botQuestionBox displayFaqFlex ${expandedItems[index] ? "bgBlue" : "bgNone"}`} onClick={() => toggleAccordion(index)}>
                        <p  className={`faqQuestions ${expandedItems[index] ? "colorWhite" : "colorBlack"}`} >{faqItem.questions}</p>
                        <div className="iconContainerMain">
                        <span className={`iconContainer ${expandedItems[index] ? "minus" : "plus"}`} >
                        </span>
                        </div></div>
                       
                       {
                        expandedItems[index] && <div className="conv-text answerBorderShow">{faqItem["answers "].replace("here.","")}
                            <a className="linkShow" href={faqItem["link"]} target="_blank">{faqItem["linkText"]}</a>
                            {faqItem["answerLists"]?<div class="answerLists">{parse(faqItem["answerLists"])}</div>:null}
                        </div>
                        }
                     </div>
                  
                </div>

            ))}

        </div>

    );

}

export default FaqItem;