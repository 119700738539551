import 'react-app-polyfill/ie11';
import 'core-js/stable';
const getCWACookies = (name) => {

 return  sessionStorage.getItem(name);
    // var nameEQ = name + '=';
    // var ca = document.cookie.split(';');
  
    // for (var i = 0; i < ca.length; i++) {
    //   var c = ca[i];
  
    //   while (c.charAt(0) === ' ') {
    //     c = c.substring(1, c.length);
    //   }
  
    //   if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    // }
  
    // return null;
  }

  export default getCWACookies;