import "react-app-polyfill/ie11";
import "core-js/stable";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Components } from "botframework-webchat-component";
import { hooks } from "botframework-webchat-component";
import WelcomeCard from "./WelcomeCard";
import WelcomeCardHBT from "./WelcomeCardHBT";
import OrderDetailCard from "./OrderDetailCard";
import ContactUs from "./ContactUsGDM";
import KBFilters from "./KBFilters";
import TSKbFilters from "./TSKbFilters";
import SearchKBFiltersGDM from "./SearchKBFiltersGDM";
import SearchKBFiltersHPS from "./SearchKBFiltersHPS";
import SearchKBFiltersUOP from "./SearchKBFiltersUOP";
import SearchKBFiltersADM from "./SearchKBFiltersADM";
import SearchKBFiltersAMRC from "./SearchKBFiltersRC";
import DisclaimerCard from "./DisclaimerCard";
import HeroCard from "./HeroCard";
import WorkFlow1 from "./WorkFlow1";
import HCEMarketingWorkflow3Card from "./HCEMarketingWorkflow3Card";
import ShowImage from "./ShowImage";
import ImageAttachments from "./ImageAttachments";
import ArticleButton from "./ArticleButton";
import CaseWebformCard from "./CaseWebformCard";
import CaseWebformCardPMTADMRCHPSUOP from "./CaseWebformCardPMTADMRCHPSUOP";
import ExitChatCard from "./ExitChatCard";
import NewHeroCard from "./NewHeroCard";
import CreateLead from './CreateLead';
import DownloadCard from './DownloadCard';
import LearnMoreCard from "./LearnMoreCard";
import DropDownCard from "./DropDownCard";
import AccessDenied from "./AccessDenied";
import htmlParser, {
  convertNodeToElement
} from "react-html-parser";
import StaticLabels from "../util/staticLabels.json";
import { AppContext } from "../util/appContext";
import TridiumHeroCard from "./TridiumHeroCard";
import TridiumArticleModal from "./TridiumArticleModal";
import liveAgentStaticLabels from "../util/liveAgentStaticLabels.json";
import { setCookie, getCookie } from '../util/cookieActions';
import FaqItem from "./FaqItem";
import LiveAgentDropDown from "./LiveAgentDropDown";
import LoadingErrorScreen from "./LoadingErrorScreen";
const { useSendMessage } = hooks;

var emoji = require("markdown-it-emoji");
var MarkdownIt = require("markdown-it");
const md = new MarkdownIt({ html: true });
md.use(emoji);
var classNames = require("classnames");

const options = {
  decodeEntities: true,
  transform
};

function transform(node, index) {
  // all links must open in a new window
  if (node.type === "tag" && node.name === "a") {
    node.attribs.target = "_blank";
    return convertNodeToElement(node, index, transform);
  }
  // return null to block certain elements
  // don't allow <span> elements
  // if (node.type === "tag" && node.name === "span") {
  //   return null;
  // }

  // Transform <ul> into <ol>
  // A node can be modified and passed to the convertNodeToElement function which will continue to render it and it's children
  // if (node.type === "tag" && node.name === "ul") {
  //   node.name = "ol";
  //   return convertNodeToElement(node, index, transform);
  // }

  // return an <i> element for every <b>
  // a key must be included for all elements
  // if (node.type === "tag" && node.name === "b") {
  //   return <i key={index}>{processNodes(node.children, transform)}</i>;
  // }

  // if (node.type === "tag" && node.name === "button") {
  //   return (
  //     <Button variant="contained" color="primary" key={index}>
  //       {processNodes(node.children, transform)}
  //     </Button>
  //   );
  // }
}
function GreetUser(userLanguage) {
  const timeFormat = AMPMFormat(new Date());
  if (timeFormat.ampm === "AM") {
    // if(timeFormat.hour >=5 && timeFormat.hour <= 11){
    //   return "Good morning!"
    // }else{
    //   return "Good morning!"
    // }
    return userLanguage
      ? StaticLabels[userLanguage.toLowerCase()].GoodMorning
      : "good morning!";
  } else {
    if (timeFormat.hour >= 4 && timeFormat.hour <= 7) {
      return userLanguage
        ? StaticLabels[userLanguage.toLowerCase()].GoodEvening
        : "good evening!";
    }
    if (timeFormat.hour >= 1 && timeFormat.hour <= 4) {
      return userLanguage
        ? StaticLabels[userLanguage.toLowerCase()].GoodAfternoon
        : "good afternoon!";
    }

    if (timeFormat.hour === 12) {
      return userLanguage
        ? StaticLabels[userLanguage.toLowerCase()].GoodAfternoon
        : "good afternoon!";
    }

    if (timeFormat.hour > 7 && timeFormat.hour) {
      return userLanguage
        ? StaticLabels[userLanguage.toLowerCase()].GoodEvening
        : "good evening!";
    }
  }
}

function AMPMFormat(date) {
  var hours = date.getHours();
  // var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  // minutes = minutes < 10 ? '0'+minutes : minutes;
  // var strTime = hours + ':' + minutes + ' ' + ampm;
  return { hour: hours, ampm: ampm };
}

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

function getAgentName(isLiveAgent) {
  if (isLiveAgent.available) return isLiveAgent.name;

  return "Honeywell Assist";
}

const MyLink = ({ children, ...props }) => {
  return <a {...props}>{children}</a>;
};

const listComponent = ({ children, ...props }) => {
  return <li {...props}>{children}</li>;
};
const Attachment = ({
  activity,
  attachments,
  content,
  contentUrl,
  contentType,
  suggestedActions,
  name,
  from,
  timestamp,
  userLanguage,
  isLiveAgent,
  setLiveAgent,
  handleModalChange,
  handleMaximizeChange,
  handleCloseModalPopup
}) => {
const [viewOriginal,setOriginality]=useState({});
  const sendMessage = useSendMessage();
  const context = useContext(AppContext);
  const handleSubmit = useCallback(
    event => {
      let value = event ? event.preventDefault() : "";
      value = "";
      sendMessage(sessionStorage.getItem("UserLastMessage"));
    },
    [sendMessage]
  );

  useEffect(() => {
    if (window.config.businessUnit === 'HBT-GCEGBT' || window.config.isPersisted) {
      setCookie(window.config.businessUnit + '-BotLastResponseTime', timestamp);
    }
  }, []);
  const [originalTimeStamp,setTimeStamp]=useState(getCookie(window.config.businessUnit + '-CheckOriginalIdValueChanged'));
 useEffect(()=>{
   let checkHistoryNew = getCookie(window.config.businessUnit + '-CheckOriginalId');
    //-CheckOriginalId
    if(checkHistoryNew){
      checkHistoryNew=JSON.parse(checkHistoryNew);
      if(Array.isArray(checkHistoryNew)){
        let found=false;
        let keyIndex =null;
        var reduced = checkHistoryNew.reduce(function(filtered, option) {
          const keysData=Object.keys(option);
          if(content?.viewOriginalLineItemsButtonId){
          if (keysData[0]==content?.viewOriginalLineItemsButtonId) {
            found=true;
            keyIndex=checkHistoryNew.findIndex(item => Object.keys(item).includes(keysData[0]));
          }
          }
          return filtered;
        }, []);
        if(content?.viewOriginalLineItemsButtonId){
        if(found){
          setOriginality((prev)=>({
            ...prev,
            [content.viewOriginalLineItemsButtonId]: checkHistoryNew[keyIndex][content.viewOriginalLineItemsButtonId]
           }));
        }
        else{
          setOriginality((prev)=>({
            ...prev,
            [content.viewOriginalLineItemsButtonId]:false
           }));  
        }
        }
      }
    }
  },[content?.viewOriginalLineItemsButtonId]);
  const handleAgentMessage = useCallback( event => {
    let checkHistory = getCookie(window.config.businessUnit + '-CheckOriginalId');
    if(checkHistory){
      checkHistory=JSON.parse(checkHistory);
     // console.log(checkHistory,"..checkHistory..");
      if(Array.isArray(checkHistory)){
        let found=false;
        let keyIndex =null;
        var reduced = checkHistory.reduce(function(filtered, option) {
          const keysData=Object.keys(option);
          if (keysData[0]==content.viewOriginalLineItemsButtonId) {
            found=true;
            keyIndex=checkHistory.findIndex(item => Object.keys(item).includes(keysData[0]));
          }
          return filtered;
        }, []);
        if(found){
       // console.log("id found",keyIndex);
        setOriginality((prev) => ({
          ...prev,
          [content.viewOriginalLineItemsButtonId]: !viewOriginal[content.viewOriginalLineItemsButtonId]
        }));
        checkHistory[keyIndex][content.viewOriginalLineItemsButtonId]=!viewOriginal[content.viewOriginalLineItemsButtonId];
        setCookie(window.config.businessUnit + '-CheckOriginalIdValueChanged',timestamp);
        setCookie(window.config.businessUnit + '-CheckOriginalId', JSON.stringify(checkHistory))
        }
        else{
          let submitObject = {
            [content.viewOriginalLineItemsButtonId]: !viewOriginal[content.viewOriginalLineItemsButtonId]
          }
          setOriginality((prev) => ({
            ...prev,
            [content.viewOriginalLineItemsButtonId]: !viewOriginal[content.viewOriginalLineItemsButtonId]
          }))
          setCookie(window.config.businessUnit + '-CheckOriginalIdValueChanged',timestamp);
          setCookie(window.config.businessUnit + '-CheckOriginalId', JSON.stringify([...checkHistory, submitObject]))
        }
      }
    }
    else{
       let submitObject = {
        [content.viewOriginalLineItemsButtonId]:!viewOriginal[content.viewOriginalLineItemsButtonId]
       }
       setCookie(window.config.businessUnit + '-CheckOriginalIdValueChanged',timestamp);
       setCookie(window.config.businessUnit + '-CheckOriginalId', JSON.stringify([submitObject])) 
        setOriginality((prev)=>({
        ...prev,
        [content.viewOriginalLineItemsButtonId]:!viewOriginal[content.viewOriginalLineItemsButtonId]
        }))
    }
  });
  // if (suggestedActions) {
  //   return (
  //     <React.Fragment>
  //       {content.includes("[GreetDCAUser]") ? (
  //         <p className={"suggestedActionHeader"}>
  //           {content
  //             .split("/")[0]
  //             .replace(" [GreetDCAUser]", `, ${GreetUser()}`)}
  //         </p>
  //       ) : (
  //         <p className={"suggestedActionHeader"}>{content.split("/")[0]}</p>
  //       )}
  //       <p className={"suggestedActionText"}>{content.split("/")[1]}</p>
  //     </React.Fragment>
  //   );
  // }

  var activityTextClass = classNames({
    userActivityClass: from === "user",
    botActivityClass: from === "bot",
    activityClass: true
  });

  var textBoxClass = classNames({
    botTextBox: from === "bot",
    userTextBox: from === "user",
    conversationTextBox: true
  });
  var liveAgentIcon = classNames({
    liveAgentIcon: true,
    liveAgentIconUser: from === "user"
  });
  var Icon = classNames({
    Icon: true,
    IconUser: from === "user"
  });
  var botAvatar = classNames({
    botAvatar: true,
    // botAvatarBot: from === "bot",
    botAvatarUser: from === "user"
    // botAvatarAgent: from === "agent"
  });
  var agentAvatar = classNames({
    agentAvatar: true
  });
  var botTimestamp = classNames({
    botTimestamp: true,
    botTimestampUser: from === "user"
    // botTimestampBot: from === "bot,"
  });
  var agentTimestamp = classNames({
    agentTimestamp: true,
    agentTimestampUser: from === "user"
    // botTimestampBot: from === "bot,"
  });

  var sendRetry = classNames({
    sendRetry: true
  });

  var agentLabel = classNames({
    agentLabel: true,
    agentLabelUser: from === "user",
    agentLabelBot: from === "bot",
    agentLabelAgent: from === "agent"
  });

  var connectLiveagentText = classNames({
    "connectLiveagentText": true
  });

  let welcomecardBotDiv = document.getElementsByClassName('welcomecardBotDivHeight');
  if (welcomecardBotDiv.length > 0 && window.config.businessUnit === "HCE-MARKETING" && contentType !== 'welcomeCard') {
    welcomecardBotDiv[0].style.height = 'auto';
  }

    // Generate a unique ID with timestamp and random number
  switch (contentType) {
    case "image/gif":
    case "image/jpeg":
    case "image/png":
    case "image/svg":
    case "image/svg+xml":
      return <img alt={name} src={contentUrl} />;
    case "text/markdown":
    case "text/plain":
      return (
        <React.Fragment>
          <div className={activityTextClass}>
            <div>
              {/* <div className={agentLabel} id="agentLabel">
                <span>{getAgentName(isLiveAgent)}</span>
              </div> */}
              <div className={textBoxClass}>
                <div className="conv-text">
                  {from === "bot"
                    ? htmlParser(md.render(content),options): content}
                </div>
              </div>
              <div className={botTimestamp}>
                <span>
                  {from === "user"
                    ? "You"
                    : window.config.businessUnit === "HCE-TRIDIUM" ? "Tridium Assist" : "Honeywell Assist"}{" "}
                  | {formatAMPM(new Date(timestamp))}
                </span>
              </div>
              <div className={sendRetry}>
                <span>Send Failed. </span>
                <a className="markdown-link" onClick={handleSubmit}>
                  Retry.
                </a>
              </div>
            </div>
            {!window.config.isNewUI &&
              (from === "user" ? (
                <div className={botAvatar} id="botAvatar">
                  <Components.Avatar
                    height={20}
                    fromUser={from === "user" ? true : false}
                  />
                </div>
              ) :
                <div className={Icon}></div>)
            }
          </div>
        </React.Fragment>
      );

    default:
      if (contentUrl) {
        return (
          <a href={contentUrl} rel="noopener noreferrer" target="_blank">
            {name}
          </a>
        );
      } else {
        switch (contentType) {
          case "welcomeCard":
            return (
             // console.log(window.config.countrySelectedOnPortal,"..window.config.countrySelectedOnPortal"),
              context.setShowSpinnerWheel(false),
              context.setMenuItems(content.buttons),
              (window.config.businessUnit === "HBT-GCEGBT") ?
              <WelcomeCardHBT content={content}
              contentType={contentType}
              handleModalChange={handleModalChange}
              handleMaximizeChange={handleMaximizeChange}
              handleCloseModalPopup={handleCloseModalPopup}
              contentPrivacy={content.privacystatement} />:window.config.showLoadingError?<LoadingErrorScreen handleModalChange={handleModalChange}
              handleMaximizeChange = {handleMaximizeChange}
              handleCloseModalPopup = {handleCloseModalPopup}  marginTopSet={true}></LoadingErrorScreen>:<WelcomeCard content={content}
                 contentType={contentType}
                 handleModalChange={handleModalChange}
                 handleMaximizeChange={handleMaximizeChange}
                 handleCloseModalPopup={handleCloseModalPopup}
                 contentPrivacy={content.privacystatement} userLanguage={window.config.businessUnit === "PMT-AMRC"?userLanguage:null}/>
                // <WelcomeCardHBT content={content}
                //   contentType={contentType}
                //   handleModalChange={handleModalChange}
                //   handleMaximizeChange={handleMaximizeChange}
                //   handleCloseModalPopup={handleCloseModalPopup}
                //   contentPrivacy={content.privacystatement} />
                // :
                // <WelcomeCard content={content}
                //   contentType={contentType}
                //   handleModalChange={handleModalChange}
                //   handleMaximizeChange={handleMaximizeChange}
                //   handleCloseModalPopup={handleCloseModalPopup}
                //   contentPrivacy={content.privacystatement} userLanguage={window.config.businessUnit === "PMT-AMRC"?userLanguage:null}/>
            );
          case "languageCard":
            return <WelcomeCard content={content} contentType={contentType} handleModalChange={handleModalChange} handleMaximizeChange={handleMaximizeChange} handleCloseModalPopup={handleCloseModalPopup} />;
          case "liveAgentDropDown":
          return <LiveAgentDropDown content={content} userLanguage={window.config.businessUnit === "PMT-AMRC"?userLanguage:window.config.language}></LiveAgentDropDown>
          case "accessDenied":
            return <AccessDenied content={content} handleModalChange={handleModalChange} />;
          case "disclaimerMessage":
            return <DisclaimerCard content={content} />;
          case "OrderDetail":
            return <OrderDetailCard content={content} />;
          case 'ContactUs':
            return <ContactUs content={content} />;
          case "application/vnd.microsoft.card.hero":
            return <HeroCard content={content} />;
          case "NewKBResult":
            return <NewHeroCard content={content} />;
          case "NewPartialKBResult":
            return <TridiumHeroCard content={content} />;
          case "accordion":
            return <FaqItem content={content} />;
          case "partialNewKBFullData":
            return <TridiumArticleModal content={content} />;
          case "CaseWebform":
            if (window.config.businessUnit == "PMT-ADM" || window.config.businessUnit == "PMT-AMRC" || window.config.businessUnit == "PMT-HPS") {
              return <CaseWebformCardPMTADMRCHPSUOP content={content} />;
            }
            else if (window.config.businessUnit == "PMT-UOP") {
              return <CaseWebformCardPMTADMRCHPSUOP content={content} />;
            }
            else {
              return <CaseWebformCard content={content} />
            }
          case "ExitChatCard":
            return (context.setLiveAgent(false), <ExitChatCard content={content} businessUnit={window.config.businessUnit} />);
          case "Workflow1":
            return <WorkFlow1 content={content} />;
          case "showImage":
            return <ShowImage content={content} />;
          case "KBResult":
            return <ArticleButton content={content} />;
          case "CreateLead":
            return <CreateLead content={content} />;
          case "DownloadCard":
            return <DownloadCard content={content} />;
          case "ImageAttachments":
            return <ImageAttachments content={content} />;
          case "LearnMoreCard":
            return <LearnMoreCard content={content} />;
          case "DropDownCard":
            return <DropDownCard content={content} />;
          case "HCEMarketingWorkflow3Card":
            return <HCEMarketingWorkflow3Card content={content} />;
          case "agentSystemMessages":
            return (
              window.config.isNewUI ?
                <div id="agentSystemMessage">
                  <p className={connectLiveagentText} style={{ marginTop: '2rem' }} >{window.config.language ? context.liveAgentDropDownPreferred ? liveAgentStaticLabels[context.liveAgentDropDownPreferred]?.connectLiveagent:liveAgentStaticLabels[window.config.language]?.connectLiveagent :context.liveAgentDropDownPreferred ? liveAgentStaticLabels[context.liveAgentDropDownPreferred]?.connectLiveagent:liveAgentStaticLabels?.en?.connectLiveagent} <br /> {context.lastTimestamp}</p>
                  <p className={connectLiveagentText}>{content.text}  <br /> {formatAMPM(new Date(timestamp))} </p>
                </div>
                :
                <div id="agentSystemMessage"> <hr />{content.text}</div>
            );

          case "conversationEnd":
            return (
              <div id="agentSystemMessage">
                <hr />
                <div>
                  {window.config.businessUnit === "HBT-GCEGBT" || window.config.businessUnit === 'SPS-ECOMM' ?
                    (context.liveAgentName ? liveAgentStaticLabels[window.config.language].leftConversationWithName1 + context.liveAgentName + liveAgentStaticLabels[window.config.language].leftConversationWithName2
                      : liveAgentStaticLabels[window.config.language].leftConversationWithoutName)
                    :
                    (context.liveAgentName ? `Our Representative ${context.liveAgentName} has left the
                    conversation`: "Our Representative has left the conversation")
                  }
                </div>
                {window.config.businessUnit === "HBT-GCEGBT" || window.config.businessUnit === 'SPS-ECOMM'?
                  <div style={{ fontSize: '12px', fontWeight: '600' }}>{liveAgentStaticLabels[window.config.language].sessionEnd}</div>
                  :
                  <div style={{ fontSize: '12px', fontWeight: '600' }}>Your session has been ended</div>}
                {window.config.businessUnit === "HBT-GCEGBT" || window.config.businessUnit === 'SPS-ECOMM' ?
                  <a
                    onClick={handleModalChange({
                      isOpenModal: false,
                      value: "Yes",
                      target: "refresh"
                    })}
                  >
                    {liveAgentStaticLabels[window.config.language].clickHere}
                  </a>
                  :
                  <a
                    onClick={handleModalChange({
                      isOpenModal: false,
                      value: "Yes",
                      target: "refresh"
                    })}
                  >
                    Click Here
                  </a>

                }
                {window.config.businessUnit === "HBT-GCEGBT" || window.config.businessUnit === 'SPS-ECOMM'?
                  <span style={{ fontSize: '12px', fontWeight: '600' }}>{liveAgentStaticLabels[window.config.language].newConversation}</span>
                  :
                  <span style={{ fontSize: '12px', fontWeight: '600' }}> to start a new conversation</span>
                }
              </div>
            );

          case "agentMessages":
            return (
              <div className="agentIcon">
                <div className="botActivityClass activityClass">
                  <div>
                  {window.config.isLACTranslationEnabled && content.viewOriginalLineItemsButtonId ? 
                  !viewOriginal[content.viewOriginalLineItemsButtonId] ? <div className="botTextBox conversationTextBox hideOriginal">
                  <div className="conv-text gridDisplay">
                    <span>{htmlParser(md.render(content.text), options)}</span>
                  </div>
                  </div>:<div className="botTextBox conversationTextBox viewOriginal">
                      <div className="conv-text gridDisplay">
                       <span>{htmlParser(md.render(content.text), options)}</span>
                       <span className="englishMessage">English: {htmlParser(md.render(content.englishText), options)}</span> 
                     </div>
                    </div>
                  :<div className="botTextBox conversationTextBox">
                      <div className="conv-text">
                        {htmlParser(md.render(content.text), options)}
                      </div>
                    </div>}
                   <div className="botTimestamp">
                   { window.config.isLACTranslationEnabled && content.viewOriginalLineItemsButtonId ?
                      <span>{formatAMPM(new Date(timestamp))}</span>:<span>{content.agentName} | {formatAMPM(new Date(timestamp))}</span>
                   }
                      {window.config.isLACTranslationEnabled  && content.viewOriginalLineItemsButtonId?
                     <span onClick={handleAgentMessage} className="linkSpan">{!viewOriginal[content.viewOriginalLineItemsButtonId]?content.preferredLanguage ? liveAgentStaticLabels[content.preferredLanguage]?.viewOriginal :
                      liveAgentStaticLabels?.en?.viewOriginal:content.preferredLanguage ? liveAgentStaticLabels[content.preferredLanguage]?.hideOriginal :
                      liveAgentStaticLabels?.en?.hideOriginal}</span>:null}
                    </div>
                  </div>
                  {!window.config.isNewUI && <div className="liveAgentIcon">
                    {content.agentName.toUpperCase().substring(0, 1)}
                  </div>}
                </div>
              </div>
            )

          default:
            if (content.actions.length === 1) {
              // eslint-disable-next-line default-case
              switch (content.actions[0].title) {
                case 'Contact Us':
                  return <ContactUs content={content} />;
                case 'KB Filters':
                  return <KBFilters content={content} />;
                case 'TS Kb Filters':
                  return <TSKbFilters content={content} />;
                case 'Search Kb Filters':
                  switch (window.config.businessUnit) {

                    case 'PMT-HPS':
                      return <SearchKBFiltersHPS content={content.actions[0].data} />;

                    case 'PMT-ADM':
                      return <SearchKBFiltersADM content={content.actions[0].data} />;

                    case 'PMT-AMRC':
                      return <SearchKBFiltersAMRC content={content.actions[0].data} userLanguage={userLanguage} />;

                    case 'SPS-GDM':
                      return <SearchKBFiltersGDM content={content} />;

                    case 'PMT-UOP':
                      return <SearchKBFiltersUOP content={content.actions[0].data} />;

                    default:
                      return <SearchKBFiltersGDM content={content} />;
                  }
              }
            }

            return (
              <pre>
                {/* {JSON.stringify(content, null, 2)} */}
                {"No renderer for given content type"}
              </pre>
            );
        }
        // return <pre>
        //   {/* {JSON.stringify(content, null, 2)} */}
        //   {"No renderer for given contenet type"}
        //   </pre>;
      }
  }
};

export default Attachment;