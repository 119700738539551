import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React, { useState, useContext } from 'react';
import { hooks } from "botframework-webchat-component";
import { AppContext } from "../util/appContext";
import Spinner from "./Spinner";
import Close from "../images/svg/Close.svg";
import StaticLabels from "../util/staticLabels.json";
import { setCookie, getCookie } from '../util/cookieActions';
import HeaderLogo_HBT from "../images/svgLatest/HeaderLogo_HBT.svg";

var classNames = require("classnames");


function GreetUser() {
  const timeFormat = formatAMPM(new Date());
  if (timeFormat.ampm === 'AM') {
    // if(timeFormat.hour >=5 && timeFormat.hour <= 11){
    //   return "Good morning!"
    // }else{
    //   return "Good morning!"
    // }
    return "Good morning"
  } else {
    if (timeFormat.hour >= 4 && timeFormat.hour <= 7) {
      return "Good evening"
    }
    if (timeFormat.hour >= 1 && timeFormat.hour <= 4) {
      return 'Good afternoon'
    }

    if (timeFormat.hour === 12) {
      return 'Good afternoon'
    }

    if (timeFormat.hour > 7 && timeFormat.hour) {
      return "Good evening"
    }
  }
}


function formatAMPM(date) {
  var hours = date.getHours();
  // var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  // minutes = minutes < 10 ? '0'+minutes : minutes;
  // var strTime = hours + ':' + minutes + ' ' + ampm;
  return { hour: hours, ampm: ampm };
}


const WelcomeCard = ({ content, contentType, handleModalChange, contentPrivacy, handleMaximizeChange, handleCloseModalPopup,userLanguage  }) => {
  let languageSelection=window.config.businessUnit === "PMT-AMRC"?userLanguage ? userLanguage:"en":window.config.language?window.config.language:"en";
  if(window.config.businessUnit === 'SPS-ECOMM'){
  if(languageSelection === "en_GB"  || languageSelection === "ja") {
    languageSelection ="en";
  } else if(languageSelection ==="fr_CA") {
    languageSelection ="fr";
  } else if(languageSelection ==="es_CO") {
    languageSelection = "es";
  } 
  }
  const { useSendMessage, useSendPostBack, useSendEvent } = hooks;
  const [languageCardSelected, setLanguageCardSelected] = useState("");
  const context = useContext(AppContext);
  const sendMessage = useSendMessage();
  const sendPostBack = useSendPostBack();

  var homeMenuoptions = classNames({
    customscrollbar: true,
    homeContainerButtonXSBG: content?.buttons?.length > 4 ? true:false,
    marginHomeButtons: window.config.businessUnit === "HCE-MARKETING"
  });

  const handleButtonClick = (selectedOption) => {
    let welcomecardBotDiv = document.getElementsByClassName('welcomecardBotDivHeight');
    if (welcomecardBotDiv.length > 0 && contentType !== 'languageCard') {
      welcomecardBotDiv[0].style.height = 'auto';
    }
    if (contentType === "welcomeCard" && window.config.businessUnit != "HCE-MARKETING") {
      context.setOptionSelected(true);
      context.setShowHeaderFooter(true);
      context.setShowSendBox(true);
      context.setEnableScroll(true);
      context.setHideStickyMenu(false);
      context.setMoreOptionsClick(false);

      if (window.config.businessUnit === 'HBT-GCEGBT' || window.config.isPersisted) {
        setCookie(window.config.businessUnit + '-Conversation', true);
      }
      sendMessage(selectedOption);
    } else if (contentType === "welcomeCard" && window.config.businessUnit === "HCE-MARKETING") {
      context.setOptionSelected(true);
      context.setShowHeaderFooter(true);
      context.setShowSendBox(false);
      context.setEnableScroll(true);
      context.setHideStickyMenu(true);
      context.setSendWelcomeCard(false);
      window.parent.postMessage({ key: 'set_dp_cookie', value: 'false' }, window.config.pageOrigin);
      window.config.dp = "false";
      sendMessage(selectedOption);
    } else {
      sendPostBack(selectedOption);
      setLanguageCardSelected(contentType);
      context.setShowSpinnerWheel(true);
    }
  }

  var headerClass = classNames({
    headerMax: true,
    header: true
  });


  const texts = content.text.split("/")

  return (
    context.showSpinnerWheel === false ?
      !getCookie(window.config.businessUnit + '-Conversation') &&
      (context.optionSelected === false &&
        languageCardSelected !== contentType &&
        <div style={{ padding: '0px', marginTop: '-13px' }}>
          <div className={headerClass}>
            <div className="ask-ellie">
              <img
                className="launcherIconAvatar launcherBlock"
                alt="miniAvatar"
                src={HeaderLogo_HBT}
              />
              <h4 style={{ fontWeight: '600' }}>Honeywell Assist</h4>
            </div>
            <ul>
              <li style={{ cursor: 'pointer' }}>
                <img alt="Close" title="Close Bot" src={Close} onClick={handleModalChange({
                  isOpenModal: false,
                  value: null,
                  target: "closeLaunchIcon"
                })}></img>
              </li>
            </ul>
          </div> 
          <div className="homeContainer">
            <div className="homeContainerFixed">
              <div style={{ marginBottom: '1rem' }} className="botTextBox conversationTextBox conversationTextBoxWelcomeMessage">
                <div className="conv-text welcomeMessage">
                {window.config.isNewPrivacy && contentType!="languageCard"?<>
                <p>{texts[0].replace("[GreetDCAUser]",languageSelection ? StaticLabels[languageSelection].welcomeShowText:StaticLabels["en"].welcomeShowText)+" "}{(window.config.businessUnit != "PMT-AMRC" && window.config.businessUnit != "PMT-ADM" && window.config.businessUnit != "PMT-UOP") ? languageSelection? StaticLabels[languageSelection].welcomeText:StaticLabels["en"].welcomeText:null}</p>
                <p className="margin_5">{languageSelection ? StaticLabels[languageSelection].chooseWelcome:StaticLabels["en"].chooseWelcome}</p></>:<p>{texts[0].replace("[GreetDCAUser]",`${GreetUser()}`)}</p>}
                {window.config.isNewPrivacy && contentType!="languageCard"? null:<><p>{texts[1]}<span>{texts[2]}</span> </p><p>{texts[3]}</p></>}
                </div>
              </div>
            </div>
            {window.config.businessUnit === 'SPS-GDM' || window.config.businessUnit === 'SPS-PSS' ?
              <div className={homeMenuoptions}>
                {content.buttons.map((item, index) => (
                  <button key={index} data-title={item.hoverText} className="btn-secondary" onClick={() => handleButtonClick(item.buttonText)}>{item.buttonText} </button>))}
              </div>
              :
              <div className={homeMenuoptions}>
                {content.buttons.map((buttonText, index) => (
                  <button key={index} className="btn-secondary" onClick={() => handleButtonClick(buttonText)}>{buttonText} </button>))}
              </div>}
            <div className='HBTLiveagentTextAlign'>
              {content.lacOperationalHoursMsg && <p className='HBTLiveagentFont'>{content.lacOperationalHoursMsg}</p>}
              {content.lacHolidaysMsg && <p className='HBTLiveagentFont'>{content.lacHolidaysMsg}</p>}
            </div>
          </div>
        </div>
      ) : (
        <Spinner
          isMaximised={true}
          showSpinner={true}
          handleModalChange={handleModalChange}
        />
      )
  )
}

export default WelcomeCard;