import 'react-app-polyfill/ie11';
import 'core-js/stable';

export const setCookie = (name, value, days) => {
  if(window.config.isChina){
    name=name+"-CH";
  }
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    if(window.config.isChina){
      document.cookie =name + "=" + (value || "") + expires + "; path=/; SameSite=none; Secure";
    }
    else{
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
  }
  export const getCookie = (name) => {
    console.log("name",name);
    if(window.config.isChina){
      name=name+"-CH";
    }
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
    }

  export const eraseCookie = (name) => {
    if(window.config.isChina){
      name=name+"-CH";
    }
    if(window.config.isChina){
      document.cookie = name +'=; Path=/; SameSite=none; Secure; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
    else{
      document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }  
  }