import "react-app-polyfill/ie11";
import "core-js/stable";
import React, { useCallback, useContext } from "react";
import { hooks } from "botframework-webchat-component";
import { AppContext } from "../util/appContext";
import setLastMessage from "../util/setLastMessage";
import WEB from '../images/svgLatest/Web.svg';
import { getCookie} from '../util/cookieActions';

var classNames = require("classnames");

const { useSendMessage, useSendEvent } = hooks;

const HeroCard = ({ content }) => {
  const sendMessage = useSendMessage();
  const context = useContext(AppContext);
  const sendEvent = useSendEvent();

  const handleClick = useCallback(
    value => event => {
      event.preventDefault();
      setLastMessage(value);
      context.setMenuOpen(false);
      if(getCookie(window.config.businessUnit + '-EnableSendBox')) {
        sendEvent('enableSendBoxEventReceived');
      }
      context.setDisableSendBox(true);
      sendMessage(value);
      content.buttons = [];
      // Sellect all elements with class heroCardButton
      var heroCardButtons = document.querySelectorAll(".heroCardButton");
      // Apply CSS property to it
      heroCardButtons.forEach(function (heroCardButton, i) {
        heroCardButton.style.border = "none";
      });  
    },
    [sendMessage]
  );

  var heroCardButton = classNames({
    heroCardButton: true,
    customscrollbar: true
  });

  return (
    <div className="heroCardContainer">
      {/* <div class="Icon"></div> */}
      <div className="heroCard">
        {/* <div className="heroCardTitle">
          <p>{content.title}</p>
        </div> */}
        <div className={heroCardButton}>
          {content.buttons.length > 0 &&
            content.buttons.map(button => {
              if (button.type === "imBack") {
                return (
                  <button id="qnaSuggestionList" className={button.channelData} onClick={handleClick(button.value)}>
                    {button.title}
                  </button>
                );
              } else if (button.type === "openUrl") {
                return (
                  <a class="viewMoreLink" style={{backgroundColor:'#007BC2', paddingTop: "10px"}}
                    href={button.value}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src={WEB} alt="web" />
                    <p>{button.title}</p> 
                  </a>
                );
              }
              return "";
            })}
            </div>
            {content.buttons.length > 0 && <div className="extraButtons" >
            {content.buttons.map(btn => {
              if (btn.type === "extra") {
                return (
                  <button onClick={handleClick(btn.value)}>
                  {btn.title}
                </button>
                )
              }
            })
            }
          </div>
         }
      </div>
    </div>
  );
};

export default HeroCard;