import "react-app-polyfill/ie11";
import "core-js/stable";
import { hooks } from "botframework-webchat-component";
import React, { useCallback, useContext } from "react";
import Markdown from "markdown-to-jsx";
import setLastMessage from "../util/setLastMessage";
import { AppContext } from "../util/appContext";
import {getCookie} from '../util/cookieActions';
import liveAgentStaticLabels from "../util/liveAgentStaticLabels.json";
require("es6-promise").polyfill();
require("isomorphic-fetch");
var classNames = require("classnames");

const { useSendMessage, useSendEvent } = hooks;

const ImBackButton = ({ cardAction: { title, value }, handleFocusSendBox }) => {
  const sendMessage = useSendMessage();
  const context = useContext(AppContext);
  const sendEvent = useSendEvent();
  const makeButtonPrimary = title?.toUpperCase() === "CHAT WITH LIVE AGENT" && window.config.sbg!="PMT" ||
    title?.toUpperCase() === "CHATTEN SIE MIT EINEM LIVE-AGENTEN" ||
    title?.toUpperCase() === "DISCUTER AVEC UN AGENT EN DIRECT" ||
    title?.toUpperCase() === "CHATTA CON L'AGENTE DAL VIVO" ||
    title?.toUpperCase() === "CHATEAR CON EL AGENTE EN VIVO" ||
    title?.toUpperCase() === "CHAT MIT LIVE AGENT" ||
    title?.toUpperCase() === "CHAT MET LIVE AGENT" ||
    title?.toUpperCase() === "AGENT EN DIRECT" ||
    title?.toUpperCase() === 'AGENTE DAL VIVO' ||
    title?.toUpperCase() === 'AGENTE EN VIVO';
  
    

  const handleClick = useCallback(() => {
    handleFocusSendBox();
    context.setMenuOpen(false);
    context.setMoreOptionsClick(false);
    if(getCookie(window.config.businessUnit + '-EnableSendBox')) {
      sendEvent('enableSendBoxEventReceived');
    }
    context.setDisableSendBox(true);
    setLastMessage(value);
    sendMessage(value);
  }, [value, sendMessage, handleFocusSendBox]);

  let suggesteActionButton = classNames({
    suggesteActionButton: true,
    "suggesteActionPrimaryButton" : makeButtonPrimary ? true : false,
    LiveAgentUnavailable: (window.config.sbg === "HBT" && title.toLowerCase() === liveAgentStaticLabels[window.config.language].LiveAgentUnavailable) ? true : false
  });

  if((window.config.businessUnit === "HBT-GCEGBT" && title.includes(liveAgentStaticLabels[window.config.language].helpfulBtn))
           || (window.config.businessUnit !== "HBT-GCEGBT" && title.includes("Helpful"))) {
    suggesteActionButton = classNames({
      suggesteActionButton: true,
      "suggestedaction-helpul": true
    });
    title = "";
  }

  if((window.config.businessUnit === "HBT-GCEGBT" && title.includes(liveAgentStaticLabels[window.config.language].notHelpfulBtn))
            || (window.config.businessUnit !== "HBT-GCEGBT" && title.includes("Not helpful"))) {
    suggesteActionButton = classNames({
      suggesteActionButton: true,
      "suggestedaction-nothelpul": true
    });
    title = "";
  }

  return (
    <button
      // ImBack is essentially sending a message
      onClick={handleClick}
      type="button"
      className={suggesteActionButton}
      disabled={window.config.sbg === "HBT" && title.toLowerCase() === liveAgentStaticLabels[window.config.language].LiveAgentUnavailable ? true : false}
      style={{backgroundColor: window.config.sbg !== "HBT" && title === "Live agent(Unavailable)"?"#707070":"",color: window.config.sbg !== "HBT" && title === "Live agent(Unavailable)"?"#ffffff":"",border:window.config.sbg !== "HBT" && title === "Live agent(Unavailable)"?" 1px solid #ffffff":""}}
    >
      <Markdown>{title}</Markdown>
    </button>
  );
};

export default ImBackButton;
