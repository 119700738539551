import "react-app-polyfill/ie11";
import "core-js/stable";
import React, {  useState, useContext, useRef, useCallback, useEffect } from "react";
import timer from '../images/svgLatest/Timer_HBT.svg';
import { AppContext } from "../util/appContext";
import { hooks } from "botframework-webchat-component";
import CaseWebformModal from './CaseWebformModal';
import {formatTime} from "../util/timer";
import liveAgentStaticLabels from "../util/liveAgentStaticLabels.json";
import {getCookie} from '../util/cookieActions';

const classNames = require("classnames");
const { useSendEvent } = hooks;
const TimerBox_Main =({ isMaximised,handleModalChange,showTimerBox,setShowTimerBox, handleSurveyCardChange, setHeaderIconsDisplay, setLiveAgent})=>{
    const [timerCount,setTimerCount]=useState(0);
    const _isMounted = useRef(true);
    const context = useContext(AppContext);
    const [showArticleModal, setArticleModal] = useState(false);
    const sendMessage = useSendEvent();
    const botTimeStamp = getCookie(window.config.businessUnit + '-BotLastResponseTime');


    function nth(n){return["st","nd","rd"][((n+90)%100-10)%10-1]||"th"}

    React.useEffect(() => {
        var interval = null
        if(_isMounted){
        if (showTimerBox) {
          setTimerCount(0);
          interval= setInterval(() => { setTimerCount((timerCount) => timerCount + 1); }, 1000);
        } else {
          clearInterval(interval);
        }
      }
        return () => { _isMounted.current = false; clearInterval(interval); }
      }, [showTimerBox]);
    
   
      const userCountMessage = context.usersCount === 0 ?  liveAgentStaticLabels["en"].checkQueue : 
       `${context.usersCount}${nth(parseInt(context.usersCount,10))}` +  liveAgentStaticLabels["en"].queueMessage2;
      
      
       function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        context.setLastTimestamp(strTime);
        return strTime;
      }

      var sendBoxContainerClass = classNames({
        "send-box-container_HBT": true,
        displayNone: !isMaximised
      });

      var connectLiveagentText = classNames({
        "connectLiveagentText": true,
        displayNone: !isMaximised
      });

      const handleArticleModalSubmit = useCallback((event, comments, communicationMode, phoneNumber) => {
        context.setMenuOpen(false);
        if (showArticleModal) {
          window.parent.postMessage({ key: 'display_article_popup', origin: window.location.origin }, window.config.pageOrigin);
          handleQuitAgentQueue();
        } else {
          window.parent.postMessage({ key: 'hide_article_popup', origin: window.location.origin}, window.config.pageOrigin);
        }
        setArticleModal(!showArticleModal);
        sendMessage('webformPopupSubmit', {webformComment: comments, preferredCommunicationMode: communicationMode + " " +phoneNumber});
      }, [showArticleModal, setArticleModal]);

     const handleArticleModalClose = useCallback( event => {
        context.setMenuOpen(false);
        if(showArticleModal){
            window.parent.postMessage({key:'display_article_popup', origin: window.location.origin}, window.config.pageOrigin);
            handleQuitAgentQueue();
        }else {
            window.parent.postMessage({key:'hide_article_popup', origin: window.location.origin}, window.config.pageOrigin);
        } 
        setArticleModal(!showArticleModal);
    },[showArticleModal,setArticleModal]);


    const handleExitAgentQueue = () => {
      sendMessage('exitchatinqueue');
      sendMessage('ExitChatInQueueCancelSession');
      sendMessage('exitQueueButton');
      setShowTimerBox(false);
      context.setLiveAgent(false);
      context.setShowSendBox(true);
      if(getCookie(window.config.businessUnit + '-EnableSendBox')) {
        sendMessage('enableSendBoxEventReceived');
      }
      context.setDisableSendBox(true);
      context.setEnableScroll(true);
    };

    const handleQuitAgentQueue = () => {
      sendMessage('CancelSession');
      handleSurveyCardChange();
      setHeaderIconsDisplay(false);
      context.setShowHeaderFooter(false);
      context.setShowGoBackbutton(false);
      context.setEnableScroll(false);
    };
    
      return(
        <div style={{ backgroundColor: "#ffffff" }}>
          <p className={connectLiveagentText} style={{ marginTop: '2rem' }}>{context.liveAgentDropDownPreferred ? liveAgentStaticLabels[context.liveAgentDropDownPreferred]?.connectLiveagent:liveAgentStaticLabels["en"].connectLiveagent} <br /> {formatAMPM(new Date(botTimeStamp))}</p>
          <div className={sendBoxContainerClass} >
              <p> <img style={{width: '1rem'}} src={timer} alt="Timer"></img></p>
              <p className="timer_HBT">{formatTime(timerCount)}</p>
              <p className="userCountText_HBT">{userCountMessage}</p>
              <button className="btn-secondary exitLiveChat_HBT" onClick={handleExitAgentQueue}>{liveAgentStaticLabels["en"].exitQueue}</button> 
           
            {showArticleModal ? <CaseWebformModal article={context.webformContent} handleArticleModalClose={handleArticleModalClose} handleArticleModalSubmit={handleArticleModalSubmit} /> : null}
          </div>
        </div>
        
      );
}

export default TimerBox_Main;